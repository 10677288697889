import moment from "moment"; 

export const GetFormattedDateYYYYMMDD = (date: any) => {
    return moment(date).format('YYYY-MM-DD')
}

export const GetFormattedDateDDMMYYYY = (date: any) => {
    return moment(date).format('DD/MM/YYYY');
}

export const GetFecha = (date: string) => {
    if (date !== null) {
        const aux = date.split('/');

        return `${aux[2]}-${aux[1]}-${aux[0]}`;
    }        
    else return '';
}