import React, { useEffect, useRef, useState } from 'react';
import { Button, Select, Text, Textarea } from '@eltintorero/apprestosb';
import { asyncFetchApi } from '../../../utils/api';
import { useEnv } from '../../../utils/Hooks/useEnv';
import { toastError, toastSuccess } from '../../../utils/Toast';
import { User } from '../../../utils/Components/User';
import { ValidateSelectStyle } from '../../../utils/Components/select';

const UserForm = ({ showTable, rowData }: { showTable: any; rowData: any }) => {
    const form = useRef<HTMLFormElement>(null);

    const [methodStyle, setMethodStyle] = useState('');

    const [username, setUsername] = useState(rowData ? rowData.username : '');
    const [password, setPassword] = useState(rowData ? rowData.password : '');
    const [nombre, setNombre] = useState(rowData ? rowData.nombre : '');
    const [apellido, setApellido] = useState(rowData ? rowData.apellido : '');
    const [direccion, setDireccion] = useState(rowData ? rowData.direccion : '');
    const [contacto, setContacto] = useState(rowData ? rowData.contacto : '');
    const [jsonData, setJsonData] = useState(rowData ? rowData.jsonData : '');
    const [role, setRole] = useState(rowData ? rowData.roles[0].cdRol : '');
    const [roleText, setRoleText] = useState(rowData ? rowData.roles[0].rol : '');

    const apis = useEnv();

    useEffect(() => {
        const currentForm: any = form.current;

        currentForm.addEventListener('submit', Validation);

        return () => {
            currentForm.removeEventListener('submit', Validation);
        };
    }, []);

    const Validation = (event: Event) => {
        const currentForm: any = form.current;

        event.preventDefault();
        event.stopPropagation();

        currentForm.classList.add('was-validated');
    };

    const SaveSubscriber = () => {
        const userData: User = {
            cdUsuario: 0,
            username: username,
            password: password,
            nombre: nombre,
            apellido: apellido,
            direccion: direccion,
            contacto: contacto,
            jsonData: jsonData,
            activo: true,
            roles: [
                {
                    cdRol: parseInt(role),
                    rol: roleText,
                },
            ],
        };

        // Edita
        if (rowData) {
            userData.cdUsuario = rowData.cdAbonado;
        }

        if (username !== '' && direccion !== '' && password !== '') {
            //console.log(userData);
            asyncFetchApi(apis.API_SET_USER, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                data: userData,
            }).then((result) => {
                if (result.error) {
                    toastError('Error a guardar los datos', '');
                } else {
                    toastSuccess('Datos cargados correctamente', '');
                    showTable();
                }
            });
        }
    };

    const HandleChange = (item: any) => {
        const value = item ? item.value : '';
        const text = item ? item.label : '';

        setRole(value);
        setRoleText(text);

        ValidateSelectStyle(value, setMethodStyle);
    };

    const GetDefaultValue = (value: string) => {
        return value !== '' ? { value: value, label: value } : '';
    };

    return (
        <form ref={form} className='needs-validation clearfix' noValidate>
            <div className='form-row'>
                <div className='col-md-3 col-xs-12'>
                    <label>Usuario</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setUsername(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={username}
                        />
                    </div>
                    <div className='invalid-feedback'>Cargar usuario</div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>Password</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setPassword(e.target.value)}
                            type='password'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={password}
                        />
                        <div className='invalid-feedback'>Cargar password</div>
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label>Nombre</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setNombre(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={nombre}
                        />
                        <div className='invalid-feedback'>Cargar nombre</div>
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label>Apellido</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setApellido(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={apellido}
                        />
                        <div className='invalid-feedback'>Cargar la apellido</div>
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-6 col-xs-12'>
                    <label>Dirección</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setDireccion(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={direccion}
                        />
                        <div className='invalid-feedback'>Cargar dirección</div>
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label>Contacto</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setContacto(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={contacto}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>Rol</label>
                    <div className='input-group'>
                        <Select
                            className={methodStyle}
                            options={[{ value: '1', label: 'Administrador' }]}
                            onChange={HandleChange}
                            placeholder={'Seleccione...'}
                            defaultValue={GetDefaultValue(role)}
                        />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-12'>
                    <label htmlFor='validationCustom12'>JSON data</label>
                    <div className='input-group'>
                        <Textarea
                            placeholder='JSON data'
                            row={5}
                            defaultValue={jsonData}
                            onChange={(e: any) => setJsonData(e.target.value)}
                            required={false}
                        />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-6'>
                    <Button float='left' onClick={() => showTable()} text='Cancelar' buttonStyle='light' />
                </div>
                <div className='col-6'>
                    <Button
                        float='right'
                        onClick={() => SaveSubscriber()}
                        text='Guardar'
                        buttonStyle='primary'
                        submit={true}
                    />
                </div>
            </div>
        </form>
    );
};

export default UserForm;
