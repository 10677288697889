export const GetDefaultValue = (value: any) => {
    return value !== '' ? value : '';
};

export const ValidateSelectStyle = (val: string, setStyle: any) => {
    if (val !== '') {
        setStyle('control-ok');
    } else {
        setStyle('control-error');
    }
};
