import React, { Component } from 'react';
import Content from '../sections/Login/Content';

const Login = ({ setToken }) => {
    return (
        <div className='ms-body ms-primary-theme ms-logged-out'>
            <main className='body-content'>
                <Content setToken={setToken} />
            </main>
        </div>
    );
};

export default Login;
