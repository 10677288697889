import { Button } from '@eltintorero/apprestosb';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../generic/spinner';
import Datatable from '../../generic/datatable';
import { asyncFetchApi } from '../../../utils/api';
import { useEnv } from '../../../utils/Hooks/useEnv';

const ConsultaContent = () => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [table, setTable] = useState(null);

    const apis = useEnv();

    const titles = [
        {
            data: 'fechaActual',
            title: 'Fecha de carga',
        },
        // {
        //     data: 'FechaOperacion',
        //     title: 'Fecha operación',
        // },
        {
            data: 'usuarioOperacion',
            title: 'Usuario operación',
        },
        {
            data: 'idTipoOperacion',
            title: 'Tipo operación',
        },
        {
            data: 'codContacto',
            title: 'Contacto',
        },
        // {
        //     data: 'Observacion',
        //     title: 'Observación',
        // },
        {
            data: 'sumaRecibos',
            title: 'Recibos',
        },
        {
            data: 'sumaTransacciones',
            title: 'Transacciones',
        },
        // {
        //     data: 'Usuario',
        //     title: 'Usuario',
        // },
        {
            data: 'finalizada',
            title: 'Fin',
        },
    ];

    useEffect(() => {
        if (table === null) {
            const url = apis.API_GET_OPERACIONES + '?IdOperacion=0&IdAbonado=0&SumaRecibos=0&SumaTransacciones=0&IdTipoOperacion=0&TipoTurno=0&Usuario=0&UsuarioOperacion=0';

            asyncFetchApi(url, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                data: {},
            }).then((result) => {
                if (result.error) {
                    console.log(result.error);
                } else {
                    setTable(result.data);
                }
            });
        }        
    }, [table]);

    const [rowData, setRowData] = useState<any>(null);

    useEffect(() => {}, [rowData, showSpinner]);

    const GoToLink = (data: any) => {
        window.open(`/Compra?id=${data.idOperacion}`, '_blank');
        console.log(data);
    };

    return (
        <div className='ms-panel'>
            <div className='ms-panel-header'>
                <h6>Consulta</h6>
            </div>
            {showSpinner ? <Spinner /> : null}
            {
                table ? <div className='ms-panel-body'>
                <Datatable
                    tableData={table}
                    titles={titles}
                    editable={true}
                    showPagination={true}
                    showSearch={true}
                    onClick={(rowData: any) => GoToLink(rowData)}
                    id={'idOperacion'}
                    tableName={'operaciones'}
                />
            </div>
                : null
            }            
        </div>
    );
};

export default ConsultaContent;
