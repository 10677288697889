import React, { useEffect } from 'react';
import { Select, Text, Textarea, Checkbox } from '@eltintorero/apprestosb';
import { GetDefaultValue } from '../../utils/Components/select';
import { useDispatch } from 'react-redux';

const OperationForm = ({
    values,
    functions,
    titleContacto,
    styles,
}: {
    values: any;
    functions: any;
    titleContacto: string;
    styles: any;
}) => {
    const dispatch = useDispatch();

    useEffect(() => {}, [values.fechaOperacion, values.idProveedor, values.observacion]);

    return (
        <>
            <div className='form-row'>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>Fecha operación</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => dispatch(functions.setFechaOperacion(e.target.value))}
                            type='date'
                            required={false}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={10}
                            pattern={''}
                            placeholder={''}
                            tabIndex={0}
                            value={values.fechaOperacion}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>Usuario</label>
                    <div className='input-group'>
                        <Select
                            className={styles.usuarioStyle}
                            options={[{ value: 0, label: 'SuperAdmin' }]}
                            onChange={(i: any) =>
                                dispatch(functions.HandleChange(i, functions.setUsuario, functions.setUsuarioStyle))
                            }
                            placeholder={'Seleccione...'}
                            defaultValue={{ value: 0, label: 'SuperAdmin' }}
                            isDisabled={true}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>{titleContacto}</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => dispatch(functions.setEncabezadoProveedor(e.target.value))}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={values.idProveedor}
                        />
                        <div className='invalid-feedback'>Cargar {titleContacto}</div>
                        {
                            // <Select
                            //     className={styles.contactoStyle}
                            //     options={[
                            //         { value: 'CONTACTO_1', label: 'Contacto 1' },
                            //         { value: 'CONTACTO_2', label: 'Contacto 2' },
                            //         { value: 'CONTACTO_3', label: 'Contacto 3' },
                            //         { value: 'CONTACTO_4', label: 'Contacto 4' },
                            //         { value: 'CONTACTO_5', label: 'Contacto 5' },
                            //     ]}
                            //     onChange={(i: any) => functions.HandleChange(i, functions.setContacto, functions.setContactoStyle)}
                            //     placeholder={'Seleccione...'}
                            //     defaultValue={GetDefaultValue(values.contacto)}
                            // />
                        }
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-12'>
                    <label htmlFor='validationCustom12'>Observación</label>
                    <div className='input-group'>
                        <Textarea
                            placeholder='Observación'
                            row={5}
                            defaultValue={values.observacion}
                            onChange={(e: any) => dispatch(functions.setObservacion(e.target.value))}
                            required={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default OperationForm;
