import React, { useEffect, useState } from 'react';
import { useEnv } from '../../../utils/Hooks/useEnv';
import Datatable from '../../generic/datatable';
import { useSelector } from 'react-redux';
import { GetFormattedDateDDMMYYYY } from '../../../utils/Dates/functions';

const TransaccionesList = ({ setRowData, setShowSpinner }: { setRowData: any; setShowSpinner: any }) => {
    const titles = [
        {
            data: 'importe',
            title: 'Importe',
        },
        {
            data: 'idCuenta',
            title: 'Cuenta',
        },
        {
            data: 'idMedioPago',
            title: 'Medio de pago',
        },
        {
            data: 'fechaTransaccion',
            title: 'Fecha de pago',
        },
    ];

    const transacciones = useSelector((state: any) => state.compra.Transaccion);
    const apis = useEnv();

    useEffect(() => {}, [transacciones]);

    useEffect(() => {
        setShowSpinner(false);
    }, [apis]);

    const GetTableData = (data: any[]): any[] => {
        const table: any[] = [];

        transacciones.map((transaccion: any) => table.push(FormattedTransaccion(transaccion)));

        return table;
    };

    const FormattedTransaccion = (transaccion: any) => {
        return {
            cdTransaccion: transaccion.cdTransaccion,
            fechaTransaccion: GetFormattedDateDDMMYYYY(transaccion.fechaTransaccion),
            proveedor: transaccion.idProveedor,
            idCuenta: transaccion.idCuenta,
            idMedioPago: transaccion.idMedioPago,
            importe: transaccion.importe,
        };
    };

    const ShowForm = (rowData: any) => {
        setRowData(rowData);
    };

    const DeleteItem = (rowData: any) => {
        alert('Borra');
    };

    return (
        <div className='mt-10'>
            {transacciones && titles.length > 0 ? (
                <Datatable
                    tableData={GetTableData(transacciones)}
                    titles={titles}
                    editable={true}
                    showPagination={false}
                    showSearch={false}
                    onClick={(rowData) => ShowForm(rowData)}
                    onDeleteClick={(rowData) => DeleteItem(rowData)}
                    id={'cdTransaccion'}
                    tableName={'transacciones'}
                />
            ) : null}
        </div>
    );
};

export default TransaccionesList;
