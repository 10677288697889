import React, { useEffect, useState } from 'react';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Datatable from '../../generic/datatable';
import { asyncFetchApi } from '../../../utils/api';
import { useEnv } from '../../../utils/Hooks/useEnv';

const DatatableContent = () => {
    const [table, setTable] = useState(null);
    const [titles, setTitles] = useState(null);

    const { API_DATATABLE } = useEnv();

    useEffect(() => {
        if (!table && API_DATATABLE !== '') {
            asyncFetchApi(API_DATATABLE + '?CdApi=0&activos=true', {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },
                data: {},
            }).then((result) => {
                setTable(result.data);
                setTitles([
                    {
                        data: 'id',
                        title: 'Customer id',
                    },
                    {
                        data: 'buyer',
                        title: 'Customer Name',
                    },
                    {
                        data: 'service',
                        title: 'service',
                    },
                    {
                        data: 'productid',
                        title: 'Product id',
                    },
                ]);
            });
        }
    }, [table, API_DATATABLE]);

    return (
        <div className='ms-panel'>
            <div className='ms-panel-header'>
                <h6>Datatable de ejemplo</h6>
            </div>
            <div className='ms-panel-body'>
                {table ? (
                    <Datatable tableData={table} titles={titles} editable={true} onClick={() => alert('hola')} />
                ) : null}
            </div>
        </div>
    );
};

export default DatatableContent;
