import jwt from 'jwt-decode';

export const storage = {
    get: (key: string) => {
        if (typeof window !== 'undefined') {
            try {
                let v;
                if (localStorage.getItem(key)) {
                    v = JSON.parse(localStorage.getItem(key) || '');
                    return v;
                }
                localStorage.removeItem(key);
                v = null;
                return v;
            } catch (e) {
                localStorage.removeItem(key);
                return null;
            }
        } else {
            return null;
        }
    },
    set: (key: string, value: any) => {
        if (typeof window !== 'undefined') {
            try {
                localStorage.setItem(key, JSON.stringify(value));
                return JSON.stringify(value);
            } catch (e) {
                return null;
            }
        } else {
            return null;
        }
    },
    remove: (key: string) => {
        if (typeof window !== 'undefined') {
            try {
                localStorage.removeItem(key);
            } catch (e) {}
        }

        return null;
    },
};

export const GetEmail = (jwtToken: string): string => {
    const json: any = jwt(jwtToken);

    return json.email;
};

export const GetCdUsuario = (jwtToken: string): number => {
    if (jwtToken) {
        const json: any = jwt(jwtToken);

        return parseInt(json.CdUsuario);
    } else {
        return -1;
    }
};
