import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './components/layouts/Layout';
import Preloader from './components/layouts/Preloader';
import Dashboard from './components/pages/Dashboard';
import Datatable from './components/pages/Datatable';
import Error from './components/pages/Error';
import Forms from './components/pages/Forms';
import Apis from './components/pages/Apis';
import Login from './components/pages/Login';
import { storage } from './utils/functions';
import Subscriber from './components/pages/Subscriber';
import Modules from './components/pages/Modules';
import User from './components/pages/User';
import Compra from './components/pages/Compra';
import Consulta from './components/pages/Consulta';

function App() {
    const [token, setToken] = useState(storage.get('tokenResto'));
    const pApis = withLayout(() => <Apis />);
    const pDashboard = withLayout(() => <Dashboard />);
    const pDatatable = withLayout(() => <Datatable />);
    const pForms = withLayout(() => <Forms />);
    const pModules = withLayout(() => <Modules />);
    const pCompra = withLayout(() => <Compra />);
    const pConsulta = withLayout(() => <Consulta />);
    const pSubscriber = withLayout(() => <Subscriber />);
    const pUser = withLayout(() => <User />);

    if (!token) {
        return <Login setToken={setToken} />;
    }

    return (
        <Router basename={'/'}>
            {/* <Preloader /> */}
            <Switch>
                <Route exact path='/' component={pDashboard} />
                <Route path='/apis' component={pApis} />
                <Route path='/datatable' component={pDatatable} />
                <Route path='/error' component={Error} />
                <Route path='/forms' component={pForms} />
                <Route path='/login' component={Login} />
                <Route path='/modules' component={pModules} />
                <Route path='/compra' component={pCompra} />
                <Route path='/consulta' component={pConsulta} />
                <Route path='/subscriber' component={pSubscriber} />
                <Route path='/user' component={pUser} />
                <Route component={Error} />
            </Switch>
        </Router>
    );
}

const withLayout = (Component: any) => (props: any) =>
    (
        <Layout>
            {/* All props are passed through to the Component being wrapped */}
            <Component {...props} />
        </Layout>
    );

export default App;
