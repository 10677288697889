import React, { useEffect, useRef, useState } from 'react';
import { Button, Radio, Select, Text, Textarea } from '@eltintorero/apprestosb';
import { Api } from '../../../utils/Components/Api';
import { asyncFetchApi } from '../../../utils/api';
import { useEnv } from '../../../utils/Hooks/useEnv';
import { toastSuccess } from '../../../utils/Toast';
import { GetDefaultValue, ValidateSelectStyle } from '../../../utils/Components/select';

const ApisForm = ({ showTable, rowData }: { showTable: any; rowData: any }) => {
    const form = useRef<HTMLFormElement>(null);

    const [methodStyle, setMethodStyle] = useState('');

    const [api, setApi] = useState(rowData ? rowData.api : '');
    const [descripcion, setDescripcion] = useState(rowData ? rowData.descripcion : '');
    const [headersAdicionales, setHeadersAdicionales] = useState(rowData ? rowData.headersAdicionales : '');
    const [method, setMethod] = useState(rowData ? rowData.metodoHttp : '');
    const [rbMock, setRbMock] = useState(rowData ? (rowData.mockActivo ? 'Si' : 'No') : 'No');
    const [responseMock, setResponseMock] = useState(rowData ? rowData.responseMock : '');
    const [rutaInterna, setRutaInterna] = useState(rowData ? rowData.rutaInterna : '');

    const apis = useEnv();

    useEffect(() => {
        const currentForm: any = form.current;

        const Validation = (event: Event) => {
            const currentForm: any = form.current;

            event.preventDefault();
            event.stopPropagation();

            currentForm.classList.add('was-validated');
            ValidateSelectStyle(method, setMethodStyle);
        };

        currentForm.addEventListener('submit', Validation);

        return () => {
            currentForm.removeEventListener('submit', Validation);
        };
    }, [methodStyle, method]);

    const HandleChange = (item: any) => {
        const value = item ? item.value : '';
        setMethod(value);
        ValidateSelectStyle(value, setMethodStyle);
    };

    const HandleRadioClick = (event: any) => {
        setRbMock(event.target.value);
    };

    const HandleTextChange = (value: string) => {
        setRutaInterna(value);
    };

    const SaveApi = () => {
        const apiData: Api = {
            activo: true,
            api: api,
            cdApi: 0,
            descripcion: descripcion,
            fechaAlta: new Date(),
            fechaBaja: undefined,
            headersAdicionales: headersAdicionales === '' ? [] : headersAdicionales,
            metodoHttp: method,
            mockActivo: rbMock === 'Si',
            responseMock: responseMock,
            rutaInterna: rutaInterna,
        };

        // Edita
        if (rowData) {
            apiData.cdApi = rowData.cdApi;
            apiData.fechaAlta = rowData.fechaAlta;
        }

        if (api !== '' && method !== '' && rutaInterna !== '') {
            asyncFetchApi(apis.API_SET_APIS, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                data: apiData,
            }).then((result) => {
                if (result.error) {
                    console.log(result.error);
                } else {
                    toastSuccess('Datos cargados correctamente', '');
                    showTable();
                }
            });
        }
    };

    return (
        <form ref={form} className='needs-validation clearfix' noValidate>
            <div className='form-row'>
                <div className='col-md-5 col-xs-12'>
                    <label>Api</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setApi(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'[a-zA-Z0-9s]+'}
                            placeholder={''}
                            tabIndex={0}
                            value={api}
                        />
                        <div className='invalid-feedback'>Cargar nombre de api</div>
                    </div>
                </div>
                <div className='col-md-2 col-xs-12'>
                    <label htmlFor='validationCustom27'>Método HTTP</label>
                    <div className='input-group'>
                        <Select
                            className={methodStyle}
                            options={[
                                { value: 'GET', label: 'GET' },
                                { value: 'POST', label: 'POST' },
                            ]}
                            onChange={HandleChange}
                            placeholder={'Seleccione...'}
                            defaultValue={GetDefaultValue(method)}
                        />
                    </div>
                </div>
                <div className='col-md-5 col-xs-12'>
                    <label htmlFor='validationCustom27'>Ruta interna</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => HandleTextChange(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={150}
                            pattern={
                                '[Hh][Tt][Tt][Pp][Ss]?://(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::d{2,5})?(?:/[^s]*)?'
                            }
                            placeholder={''}
                            tabIndex={0}
                            value={rutaInterna}
                        />
                        <div className='invalid-feedback'>Cargar ruta interna</div>
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-6'>
                    <label htmlFor='validationCustom12'>Descripción</label>
                    <div className='input-group'>
                        <Textarea
                            placeholder='Descripción'
                            row={5}
                            defaultValue={descripcion}
                            onChange={(e: any) => setDescripcion(e.target.value)}
                            required={false}
                        />
                    </div>
                </div>
                <div className='col-md-6'>
                    <label htmlFor='validationCustom12'>Headers adicionales</label>
                    <div className='input-group'>
                        <Textarea
                            placeholder='Adicionales'
                            row={5}
                            defaultValue={headersAdicionales}
                            onChange={(e: any) => setHeadersAdicionales(e.target.value)}
                            required={false}
                        />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-9'>
                    <label htmlFor='validationCustom12'>Response Mock</label>
                    <div className='input-group'>
                        <Textarea
                            placeholder='Mock'
                            row={5}
                            defaultValue={responseMock}
                            onChange={(e: any) => setResponseMock(e.target.value)}
                            required={false}
                        />
                    </div>
                </div>
                <div className='col-md-3'>
                    <label htmlFor='validationCustom12'>Mock activo</label>
                    <div className='input-group'>
                        <Radio
                            id={'rbMockActivo'}
                            onChange={(e: any) => HandleRadioClick(e)}
                            opciones={[
                                {
                                    text: 'Si',
                                    value: 'Si',
                                },
                                {
                                    text: 'No',
                                    value: 'No',
                                },
                            ]}
                            horizontal={false}
                            selectedValue={rbMock}
                        />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-6'>
                    <Button float='left' onClick={() => showTable()} text='Cancelar' buttonStyle='light' />
                </div>
                <div className='col-6'>
                    <Button
                        float='right'
                        onClick={() => SaveApi()}
                        text='Guardar'
                        buttonStyle='primary'
                        submit={true}
                    />
                </div>
            </div>
        </form>
    );
};

export default ApisForm;
