import React, { useEffect, useRef, useState } from 'react';
import { Button, Text } from '@eltintorero/apprestosb';
import axios from 'axios';
import { useEnv } from '../../../utils/Hooks/useEnv';
import { storage } from '../../../utils/functions';
import { useHistory } from 'react-router';

const Content = ({ setToken }: { setToken: any }) => {
    const form = useRef<HTMLFormElement>(null);
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [error, setError] = useState(false);
    const apis = useEnv();
    const history = useHistory();

    useEffect(() => {
        const currentForm: any = form.current;

        currentForm.addEventListener('submit', Validation);

        return () => {
            currentForm.removeEventListener('submit', Validation);
        };
    }, []);

    useEffect(() => {
        if (storage.get('tokenResto') !== null) history.push('/');
    }, storage.get('tokenResto'));

    const Validation = (event: any) => {
        const currentForm: any = form.current;

        event.preventDefault();
        event.stopPropagation();

        currentForm.classList.add('was-validated');
    };

    const HandleUser = (value: string) => {
        setError(false);
        setUser(value);
    };

    const HandlePass = (value: string) => {
        setError(false);
        setPass(value);
    };

    const handleLogin = (setToken: any) => {
        if (user !== '' && pass !== '') {
            setButtonDisabled(true);

            const bodyFormData = {
                username: user,
                password: pass,
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            axios
                .post(apis.API_BASE_URL + apis.API_LOGIN, bodyFormData, config)
                .then((response) => {
                    setToken(response.data.token);
                    storage.set('tokenResto', response.data.token);
                })
                .catch((error) => {
                    setError(true);
                    setButtonDisabled(false);
                });
        }
    };

    return (
        <div className='ms-content-wrapper ms-auth'>
            <div className='ms-auth-container'>
                <div className='ms-auth-col'>
                    <div className='ms-auth-bg' />
                </div>
                <div className='ms-auth-col'>
                    <div className='ms-auth-form'>
                        <form ref={form} className='needs-validation' noValidate>
                            <h3>Iniciar sesión</h3>
                            <p>Ingresá tu usuario y contraseña</p>
                            <div className='mb-3'>
                                <label htmlFor='validationCustom08'>Usuario</label>
                                <div className='input-group'>
                                    <Text
                                        onChange={(e: any) => HandleUser(e.target.value)}
                                        type='text'
                                        required={true}
                                        autoComplete={false}
                                        autoFocus={false}
                                        disabled={false}
                                        inputMode={'text'}
                                        maxLength={100}
                                        pattern={'[a-zA-Z0-9s]+'}
                                        placeholder={'Usuario'}
                                        tabIndex={0}
                                        value={user}
                                    />
                                    <div className='invalid-feedback'>El usuario no puede ser vacío.</div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <label htmlFor='validationCustom09'>Contraseña</label>
                                <div className='input-group'>
                                    <Text
                                        onChange={(e: any) => HandlePass(e.target.value)}
                                        type='password'
                                        required={true}
                                        autoComplete={false}
                                        autoFocus={false}
                                        disabled={false}
                                        inputMode={'text'}
                                        maxLength={100}
                                        placeholder={'Contraseña'}
                                        tabIndex={0}
                                        value={pass}
                                    />
                                    <div className='invalid-feedback'>La contraseña es obligatoria.</div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label className='ms-checkbox-wrap'>
                                    <input className='form-check-input' type='checkbox' />
                                    <i className='ms-checkbox-check' />
                                </label>
                                <span> Recordarme en este equipo </span>
                                {/* <label className='d-block mt-3'>
                                    <Link to='/default-login' className='btn-link' onClick={this.handleShow1}>
                                        Forgot Password?
                                    </Link>
                                </label> */}
                            </div>
                            <div>
                                <Button
                                    float='right'
                                    onClick={() => handleLogin(setToken)}
                                    text='Ingresar'
                                    buttonStyle={'primary'}
                                    submit={true}
                                    disabled={buttonDisabled}
                                    size='xl'
                                />
                            </div>
                            {error ? (
                                <div>
                                    <label className='mt-8 ms-text-primary'>Usuario o contraseña inválidos</label>
                                </div>
                            ) : null}

                            {/* <p className='mb-0 mt-3 text-center'>
                                Don't have an account?{' '}
                                <Link className='btn-link' to='/default-register'>
                                    Create Account
                                </Link>
                            </p> */}
                        </form>
                    </div>
                </div>
            </div>
            {/* <Modal
                className='modal-min'
                show={this.state.show1}
                onHide={this.handleClose}
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <Modal.Body className='text-center'>
                    <button type='button' className='close' onClick={this.handleClose}>
                        <span aria-hidden='true'>×</span>
                    </button>
                    <i className='flaticon-secure-shield d-block' />
                    <h1>Forgot Password?</h1>
                    <p>Enter your email to recover your password</p>
                    <form method='post'>
                        <div className='ms-form-group has-icon'>
                            <input
                                type='text'
                                placeholder='Email Address'
                                className='form-control'
                                name='forgot-password'
                            />{' '}
                            <i className='material-icons'>email</i>
                        </div>
                        <button type='submit' className='btn btn-primary shadow-none'>
                            Reset Password
                        </button>
                    </form>
                </Modal.Body>
            </Modal> */}
        </div>
    );
};

export default Content;
