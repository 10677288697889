import { Button } from '@eltintorero/apprestosb';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../generic/spinner';
import OperationForm from './CompraForm';

const CompraContent = () => {
    // Obtener la cadena de consulta
    const queryString = window.location.search;

    // Parsear la cadena de consulta a un objeto
    const params = new URLSearchParams(queryString);

    const idCompra = params.get('id');

    console.log('idCompra: ', idCompra);

    const [showSpinner, setShowSpinner] = useState(false);
    const [table, setTable] = useState(false);
    const [rowData, setRowData] = useState<any>(null);

    useEffect(() => {}, [rowData, showSpinner]);

    const NewOperation = () => {
        setTable(false);
        setRowData(false);
    };

    const ShowTable = () => {
        setTable(true);
        setRowData(null);
    };

    return (
        <div className='ms-panel'>
            <div className='ms-panel-header'>
                <h6>Compra</h6>
            </div>
            {showSpinner ? <Spinner /> : null}
            <div className='ms-panel-body'>
                <OperationForm showTable={false} rowData={rowData} />
            </div>
        </div>
    );
};

export default CompraContent;
