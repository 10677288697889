import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompra, Recibo, Transaccion } from '../../types/CompraTypes';
import { GetFormattedDateYYYYMMDD } from '../../../utils/Dates/functions';
import { GetCdUsuario, storage } from '../../../utils/functions';

const initialState = {
    encabezado: {
        fechaOperacion: GetFormattedDateYYYYMMDD(new Date()),
        usuarioOperacion: GetCdUsuario(storage.get('tokenResto')),
        idProveedor: '',
        observacion: '',
        finalizada: false,
    },
    recibo: [],
    Transaccion: [],
    recurrencia: {
        fechaDesde: null,
        fechaHasta: null,
        idFrecuencia: 0,
    },
};

const compraSlice: any = createSlice({
    name: 'compra',
    initialState: initialState,
    reducers: {
        useRestartCompra(state: ICompra = initialState) {
            return initialState;
        },
        useSetEncabezadoFechaOperacion(state: ICompra = initialState, action: PayloadAction<string>) {
            state.encabezado!.fechaOperacion = action.payload;
        },
        useSetEncabezadoProveedor(state: ICompra = initialState, action: PayloadAction<string>) {
            state.encabezado!.idProveedor = action.payload;
        },
        useSetEncabezadoObservacion(state: ICompra = initialState, action: PayloadAction<string>) {
            state.encabezado!.observacion = action.payload;
        },
        useSetEncabezadoUsuarioOperacion(state: ICompra = initialState, action: PayloadAction<number>) {
            state.encabezado!.usuarioOperacion = action.payload;
        },
        useSetFinalizada(state: ICompra = initialState, action: PayloadAction<boolean>) {
            state.encabezado!.finalizada = action.payload;
        },
        useSetReciboCompra(state: ICompra = initialState, action: PayloadAction<Recibo>) {
            state.recibo = [...state.recibo!, action.payload];
        },
        useUpdateReciboCompra(state: ICompra = initialState, action: PayloadAction<Recibo>) {
            const newRecibo = state.recibo!.map((recibo: any) => {
                if (recibo.nroRecibo === action.payload.nroRecibo) return action.payload;
                else return recibo;
            });
            state.recibo = newRecibo;
        },
        useSetTransaccionCompra(state: ICompra = initialState, action: PayloadAction<Transaccion>) {
            state.Transaccion = [...state.Transaccion!, action.payload];
        },
        useUpdateTransaccionCompra(state: ICompra = initialState, action: PayloadAction<Transaccion>) {
            const newTransacciones = state.Transaccion!.map((transaccion: any) => {
                if (transaccion.cdTransaccion === action.payload.cdTransaccion) return action.payload;
                else return transaccion;
            });
            state.Transaccion = newTransacciones;
        },
        useSetRecurrenciaFechaDesde(state: ICompra = initialState, action: PayloadAction<string>) {
            state.recurrencia!.fechaDesde = action.payload;
        },
        useSetRecurrenciaFechaHasta(state: ICompra = initialState, action: PayloadAction<string>) {
            state.recurrencia!.fechaHasta = action.payload;
        },
        useSetRecurrenciaIdFrecuencia(state: ICompra = initialState, action: PayloadAction<number>) {
            state.recurrencia!.idFrecuencia = action.payload;
        },
    },
});

export const {
    useRestartCompra,
    useSetEncabezadoFechaOperacion,
    useSetEncabezadoProveedor,
    useSetEncabezadoObservacion,
    useSetEncabezadoUsuarioOperacion,
    useSetFinalizada,
    useSetReciboCompra,
    useUpdateReciboCompra,
    useSetTransaccionCompra,
    useUpdateTransaccionCompra,
    useSetRecurrenciaFechaDesde,
    useSetRecurrenciaFechaHasta,
    useSetRecurrenciaIdFrecuencia,
} = compraSlice.actions;

export default compraSlice.reducer;
