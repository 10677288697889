import { asyncFetchApi } from '../../../../utils/api';

export const GetSubscribers = (apis: any, thenFunctions: any, setShowSpinner: any, setError: any) => {
    asyncFetchApi(apis.API_GET_SUBSCRIBER + '?CdSubscriber=0&activos=true', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: {},
    }).then((result) => {
        if (result.error) {
            console.log(result.error);
            setError(true);
        } else {
            thenFunctions(result);
        }

        setShowSpinner(false);
    });
};
