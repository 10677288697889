import { asyncFetchApi } from '../../../../utils/api';

export const GetRecibosType = (apis: any, setShowSpinner: any, setReciboType: any) => {
    asyncFetchApi(apis.API_GET_TIPO_RECIBO + '?IdTipoRecibo=0', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: {},
    }).then((result) => {
        if (result.error) {
            console.log(result.error);
        } else {
            const newReciboType = result.data.map((data: any) => {
                return {
                    value: data.idTipoRecibo,
                    label: data.tipoRecibo,
                };
            });

            setReciboType(newReciboType);
        }

        setShowSpinner(false);
    });
};
