import React, { useEffect } from 'react';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import $ from 'jquery';
import ReactDOM from 'react-dom';

export interface IProps {
    id: string;
    tableName?: string;
    tableData: any[];
    titles: any[];
    editable: boolean;
    showSearch?: boolean;
    showPagination?: boolean;
    onClick?: (row: any) => void;
    onDeleteClick?: (row: any) => void;
    onChecked?: (row: any) => void;
}

const Datatable = (props: IProps) => {
    const columnDefs: any[] = [];
    const tableName = typeof props.tableName === 'undefined' ? 'tableName' : props.tableName;
    const showSearch = typeof props.showSearch === 'undefined' ? true : props.showSearch;
    const showPagination = typeof props.showPagination === 'undefined' ? true : props.showPagination;

    useEffect(() => {
        // if ($('#tableName').html() !== '') {
        //     const table: any = $('#tableName').DataTable();
        //     table.clear();
        // }

        if (props.editable) {
            props.titles.push({
                data: props.id,
                responsivePriority: 2,
                render: function () {
                    return '';
                },
                visible: true,
                sortable: false,
                className: 'actionCol noVis',
                title: '',
            });

            columnDefs.push({
                targets: props.titles.length - 1,
                createdCell: (td: any, cellData: any, rowData: any, row: any, col: any) =>
                    ReactDOM.render(
                        <div className='table-actions'>
                            {props.onClick ? (
                                <a
                                    className='clear-button'
                                    onClick={() => (props.onClick ? props.onClick(rowData) : null)}
                                    title='Editar'
                                >
                                    <i className='fas fa-pencil-alt text-secondary' />
                                </a>
                            ) : null}
                            &nbsp;&nbsp;
                            {props.onDeleteClick ? (
                                <a
                                    className='clear-button'
                                    onClick={() => (props.onDeleteClick ? props.onDeleteClick(rowData) : null)}
                                    title='Borrar'
                                >
                                    <i className='far fa-trash-alt ms-text-danger'></i>
                                </a>
                            ) : null}
                        </div>,
                        td,
                    ),
            });
        }

        $('#' + tableName).DataTable({
            data: props.tableData,
            columns: props.titles,
            retrieve: true,
            columnDefs: columnDefs,
            searching: showSearch,
            paging: showPagination,
            info: false,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
            },
        });
    }, []);

    return (
        <div className='table-responsive'>
            <table id={tableName} className='table table-striped w-100 thead-primary' />
        </div>
    );
};

export default Datatable;
