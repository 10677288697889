import React, { Component } from 'react';
import FormsContent from '../sections/Forms/FormsContent';

class Forms extends Component {
    render() {
        return <FormsContent />;
    }
}

export default Forms;
