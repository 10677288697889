import React, { useEffect, useState } from 'react';
import TransaccionesList from './TransaccionesList';
import { Spinner } from '../../generic/spinner';
import { Button } from '@eltintorero/apprestosb';
import TransaccionesForm from './TransaccionesForm';

const TransaccionesContent = ({ values, functions }: { values: any; functions: any }) => {
    const [showSpinner, setShowSpinner] = useState(true);
    const [table, setTable] = useState(true);
    const [rowData, setRowData] = useState<any>(null);

    useEffect(() => {}, [rowData, showSpinner]);

    const NewPago = () => {
        setTable(false);
        setRowData(false);
    };

    const ShowTable = () => {
        setTable(true);
        setRowData(null);
    };

    return (
        <>
            {showSpinner ? <Spinner /> : null}
            <div className='ms-panel-body'>
                {table && !rowData ? (
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Button
                                    float='right'
                                    onClick={() => NewPago()}
                                    text='Nuevo pago'
                                    buttonStyle='primary'
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <TransaccionesList setRowData={setRowData} setShowSpinner={setShowSpinner} />
                            </div>
                        </div>
                    </>
                ) : (
                    <TransaccionesForm
                        rowData={rowData}
                        showTable={ShowTable}
                        setShowSpinner={setShowSpinner}
                        values={values}
                        functions={functions}
                    />
                )}
            </div>
        </>
    );
};

export default TransaccionesContent;
