import React, { useEffect, useState } from 'react';
import { useEnv } from '../../../utils/Hooks/useEnv';
import Datatable from '../../generic/datatable';
import { useSelector } from 'react-redux';
import { GetFormattedDateDDMMYYYY } from '../../../utils/Dates/functions';

const RecibosList = ({ setRowData, setShowSpinner }: { setRowData: any; setShowSpinner: any }) => {
    const titles = [
        {
            data: 'importe',
            title: 'Importe',
        },
        {
            data: 'nroRecibo',
            title: 'Número de recibo',
        },
        {
            data: 'fechaRecibo',
            title: 'Fecha recibo',
        },
    ];

    const apis = useEnv();
    const recibos = useSelector((state: any) => state.compra.recibo);

    useEffect(() => {
        setShowSpinner(false);
    }, []);

    const ShowForm = (rowData: any) => {
        setRowData(rowData);
    };

    const GetTableData = (data: any[]): any[] => {
        const table: any[] = [];

        recibos.map((recibo: any) => table.push(FormattedRecibo(recibo)));

        return table;
    };

    const FormattedRecibo = (recibo: any) => {
        return {
            cdRecibo: recibo.cdRecibo,
            nroRecibo: recibo.nroRecibo,
            importe: recibo.importe,
            fechaRecibo: GetFormattedDateDDMMYYYY(recibo.fechaRecibo),
            esAdicional: recibo.esAdicional,
            descuento: recibo.descuento,
            fechaVencimientoRecibo: recibo.fechaVencimientoRecibo,
            observacion: recibo.observacion,
            idProveedor: recibo.idProveedor,
            idTipoRecibo: recibo.idTipoRecibo,
            detalleRecibo: recibo.detalleRecibo,
        };
    };

    return (
        <div className='mt-10'>
            {recibos && titles.length > 0 ? (
                <Datatable
                    tableData={GetTableData(recibos)}
                    titles={titles}
                    editable={true}
                    showPagination={false}
                    showSearch={false}
                    onClick={(rowData) => ShowForm(rowData)}
                    id={'cdRecibo'}
                    tableName={'recibos'}
                />
            ) : (
                'Sin recibos'
            )}
        </div>
    );
};

export default RecibosList;
