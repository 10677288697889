import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Content extends Component {
    render() {
        return (
            <div className='ms-body ms-primary-theme'>
                <main className='body-content ms-error-404'>
                    <div className='ms-content-wrapper'>
                        <i className='flaticon-computer' />
                        <h1>Error 404</h1>
                        <h3>Oops! Página no encontrada!</h3>
                        <p>Página inexistente.</p>
                        <Link to='/' className='btn btn-white'>
                            <i className='material-icons'>arrow_back</i> Volver a la Home
                        </Link>
                    </div>
                </main>
            </div>
        );
    }
}

export default Content;
