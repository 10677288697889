import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox } from '@eltintorero/apprestosb';
import { Accordion, Card } from 'react-bootstrap';
import { asyncFetchApi } from '../../../utils/api';
import { useEnv } from '../../../utils/Hooks/useEnv';
import { ValidateSelectStyle } from '../../../utils/Components/select';
import OperationForm from '../../shared/OperationForm';
import RecibosContent from '../../shared/Recibos/RecibosContent';
import TransaccionesContent from '../../shared/Transacciones/TransaccionesContent';
import Recurrencias from '../../shared/Recurrencias';
import { GetFormattedDateYYYYMMDD } from '../../../utils/Dates/functions';
import { useDispatch, useSelector } from 'react-redux';
import { ICompra } from '../../../reduxToolkit/types/CompraTypes';
import {
    useRestartCompra,
    useSetEncabezadoFechaOperacion,
    useSetEncabezadoObservacion,
    useSetEncabezadoProveedor,
    useSetEncabezadoUsuarioOperacion,
    useSetFinalizada,
    useSetRecurrenciaFechaDesde,
    useSetRecurrenciaFechaHasta,
    useSetRecurrenciaIdFrecuencia,
    useSetTransaccionCompra,
} from '../../../reduxToolkit/slice/CompraSlice';
import { toastSuccess } from '../../../utils/Toast';
import { Link } from 'react-router-dom';

const CompraForm = ({ showTable, rowData }: { showTable: any; rowData: any }) => {
    const form = useRef<HTMLFormElement>(null);

    const [contactoStyle, setContactoStyle] = useState('');
    const [usuarioStyle, setUsuarioStyle] = useState('');

    const [totalRecibo, setTotalRecibo] = useState(0);
    const [totalPagos, setTotalPagos] = useState(0);

    // Recibo
    const [reciboStyle, setReciboStyle] = useState([]);
    const [recibo, setRecibo] = useState(rowData ? rowData.recibo : '');

    // Pago
    const [fechaTransaccion, setFechaTransaccion] = useState(GetFormattedDateYYYYMMDD(new Date()));
    const [cuentaStyle, setCuentaStyle] = useState('');
    const [medioPagoStyle, setMedioPagoStyle] = useState('');
    const [cuenta, setCuenta] = useState(rowData ? rowData.cuenta : '');
    const [medioPago, setMedioPago] = useState(rowData ? rowData.medioPago : '');
    const [proveedor, setProveedor] = useState(rowData ? rowData.idProveedor : '');
    const [importeTransaccion, setImporteTransaccion] = useState(rowData ? rowData.importe : 0);

    // Recurrencias
    const [frecuenciaStyle, setFrecuenciaStyle] = useState('');

    const apis = useEnv();

    const dispatch = useDispatch();
    const setFechaOperacion = useSetEncabezadoFechaOperacion;
    const setEncabezadoProveedor = useSetEncabezadoProveedor;
    const setObservacion = useSetEncabezadoObservacion;
    const setEncabezadoUsuarioOperacion = useSetEncabezadoUsuarioOperacion;
    const setTransaccionCompra = useSetTransaccionCompra;
    const setRecurrenciaFechaDesde = useSetRecurrenciaFechaDesde;
    const setRecurrenciaFechaHasta = useSetRecurrenciaFechaHasta;
    const setRecurrenciaIdFrecuencia = useSetRecurrenciaIdFrecuencia;
    const setFinalizada = useSetFinalizada;
    const restartCompra = useRestartCompra;
    const compra = useSelector((state: any) => state.compra);

    const encabezado = compra.encabezado;

    useEffect(() => {
        const currentForm: any = form.current;

        currentForm.removeEventListener('submit', Validation);
        currentForm.addEventListener('submit', Validation);

        return () => {
            currentForm.removeEventListener('submit', Validation);
        };
    }, [encabezado.usuarioOperacion, cuenta, recibo]);

    useEffect(() => {}, [encabezado.finalizada]);

    const HandleChange = (item: any, setValue: any, setStyle: any) => {
        const value = item ? item.value : '';
        setValue(value);
        ValidateSelectStyle(value, setStyle);
    };

    const Validation = (event: Event) => {
        const currentForm: any = form.current;
        event.preventDefault();
        event.stopPropagation();

        // ValidateSelectStyle(contacto, setContactoStyle);
        ValidateSelectStyle(encabezado.usuarioOperacion, setUsuarioStyle);
        ValidateSelectStyle(recibo, setReciboStyle);
        ValidateSelectStyle(cuenta, setCuentaStyle);
        ValidateSelectStyle(medioPago, setMedioPagoStyle);

        currentForm.classList.add('was-validated');
    };

    const SaveTransacciones = (showTable: any) => {
        const transaccionObject = {
            cdTransaccion: 0,
            fechaTransaccion: fechaTransaccion !== '' ? GetFormattedDateYYYYMMDD(new Date(fechaTransaccion)) : null,
            idProveedor: proveedor,
            idCuenta: cuenta,
            idMedioPago: medioPago,
            importe: Number(importeTransaccion),
        };

        dispatch(setTransaccionCompra(transaccionObject));
        showTable(true);
    };

    const SetRecFechaDesde = (value: string) => {
        dispatch(setRecurrenciaFechaDesde(value));
    };

    const SetRecFechaHasta = (value: string) => {
        dispatch(setRecurrenciaFechaHasta(value));
    };

    const SetRecFrecuencia = (value: number) => {
        dispatch(setRecurrenciaIdFrecuencia(value));
    };

    const GetTotalRecibo = (recibos: any[]) => {
        let total = 0;

        recibos &&
            recibos.forEach((recibo: any) => recibo.detalleRecibo.forEach((detalle: any) => (total += detalle.total)));

        return total;
    };

    const GetTotalPagos = (pagos: any[]) => {
        let total = 0;

        pagos && pagos.forEach((pago: any) => (total += pago.importe));

        return total;
    };

    const SaveOperation = () => {
        asyncFetchApi(apis.API_SET_OPERACIONES_COMPLETAS, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: compra,
        }).then((result) => {
            if (result.error) {
                console.log(result.error);
            } else {
                toastSuccess('Datos cargados correctamente', '');
                dispatch(restartCompra());
            }
        });
    };

    useEffect(() => {
        setTotalRecibo(GetTotalRecibo(compra.recibo));
        setTotalPagos(GetTotalPagos(compra.Transaccion));
    }, [compra.recibo, compra.Transaccion, totalRecibo, totalPagos]);

    return (
        <form ref={form} className='needs-validation clearfix' noValidate>
            <Accordion defaultActiveKey='0' className='has-gap ms-accordion-chevron'>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey='0'>
                        <span> Encabezado </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse className='collapseparent' eventKey='0'>
                        <Card.Body>
                            <OperationForm
                                values={encabezado}
                                functions={{
                                    setFechaOperacion,
                                    setEncabezadoProveedor,
                                    setEncabezadoUsuarioOperacion,
                                    setUsuarioStyle,
                                    setObservacion,
                                    HandleChange,
                                }}
                                titleContacto={'Proveedor'}
                                styles={{ usuarioStyle, contactoStyle }}
                            />
                        </Card.Body>
                    </Accordion.Collapse>                    
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey='1'>
                        <span> Recibo de compras </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse className='collapseparent' eventKey='1'>
                        <Card.Body>
                            <RecibosContent
                                values={{ recibo, reciboStyle }}
                                functions={{ setRecibo, setReciboStyle }}
                            />
                        </Card.Body>
                    </Accordion.Collapse>                    
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey='2'>
                        <span> Pagos </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse className='collapseparent' eventKey='2'>
                        <Card.Body>
                            <TransaccionesContent
                                values={{
                                    cuenta,
                                    cuentaStyle,
                                    medioPago,
                                    medioPagoStyle,
                                    proveedor,
                                    fechaTransaccion,
                                    importeTransaccion,
                                }}
                                functions={{
                                    setCuenta,
                                    setCuentaStyle,
                                    setMedioPago,
                                    setMedioPagoStyle,
                                    setProveedor,
                                    setFechaTransaccion,
                                    setImporteTransaccion,
                                    SaveTransacciones,
                                }}
                            />
                        </Card.Body>
                    </Accordion.Collapse>                    
                </Card>
                {
                    // <Card>
                    //     <Accordion.Collapse className='collapseparent' eventKey='3'>
                    //         <Card.Body>
                    //             <Recurrencias
                    //                 values={{
                    //                     fechaDesde: compra.recurrencia.fechaDesde,
                    //                     fechaHasta: compra.recurrencia.fechaHasta,
                    //                     idFrecuencia: compra.recurrencia.idFrecuencia,
                    //                     frecuenciaStyle,
                    //                 }}
                    //                 functions={{
                    //                     SetRecFechaDesde,
                    //                     SetRecFechaHasta,
                    //                     SetRecFrecuencia,
                    //                     setFrecuenciaStyle,
                    //                 }}
                    //             />
                    //         </Card.Body>
                    //     </Accordion.Collapse>
                    //     <Accordion.Toggle as={Card.Header} eventKey='3'>
                    //         <span> Recurrencias </span>
                    //     </Accordion.Toggle>
                    // </Card>
                }
            </Accordion>
            <div className='form-row'>
                <div className='col-xl-4 col-md-4'>
                    <div className='ms-card card-success ms-widget ms-infographics-widget'>
                        <div className='ms-card-body media'>
                            <div className='media-body'>
                                <h6>Total recibos</h6>
                                <p className='ms-card-change'>{`$${totalRecibo}`}</p>
                            </div>
                        </div>
                        <i className='fa fa-plus' />
                    </div>
                </div>
                <div className='col-xl-4 col-md-4'>
                    <div className='ms-card card-danger ms-widget ms-infographics-widget'>
                        <div className='ms-card-body media'>
                            <div className='media-body'>
                                <h6>Total pagos</h6>
                                <p className='ms-card-change'>{`$${totalPagos}`}</p>
                            </div>
                        </div>
                        <i className='fa fa-minus' />
                    </div>
                </div>
                <div className='col-xl-4 col-md-4'>
                    <div className='ms-card card-secondary ms-widget ms-infographics-widget'>
                        <div className='ms-card-body media'>
                            <div className='media-body'>
                                <h6>Saldo</h6>
                                <p className='ms-card-change'>{`$${totalRecibo - totalPagos}`}</p>
                            </div>
                        </div>
                        <i className='far fa-handshake' />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-12'>
                    <Checkbox
                        onChange={(e: any) => dispatch(setFinalizada(!encabezado.finalizada))}
                        value={'Si'}
                        selected={encabezado.finalizada}
                        text={'Finalizada'}
                    />
                </div>
            </div>
            <div className='form-row'>
                <div className='col-6'>
                    <Link to="/consulta" className="btn btn-light">Cancelar</Link>
                </div>
                <div className='col-6'>
                    <Button
                        float='right'
                        onClick={() => SaveOperation()}
                        text='Guardar'
                        buttonStyle='primary'
                        submit={true}
                    />
                </div>
            </div>
        </form>
    );
};

export default CompraForm;
