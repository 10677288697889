import Swal from 'sweetalert2';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Button, ControlledText } from '@eltintorero/apprestosb';
import { useEffect, useReducer, useState } from 'react';

export const ModalDetalleRecibo = (open: boolean, setOpen: any, addDetalleRecibo: any, editData: any) => {

    const defaultData = {
        codProducto: '',
        descripcion: '',
        unidadMedida: '',
        cantidad: 0,
        monto: 0,
        descuentos: 0,
        total: 0,
        periodoInicio: '',
        periodoFin: '',
    }

    const [edit, setEdit] = useState(true);
    const [data, updateData] = useReducer(
        (prev: any, next: any) => {
            const updateData = { ...prev, ...next };

            return updateData;
        }, defaultData,
    );    

    const SaveDetalleRecibo = () => {
        addDetalleRecibo(data, editData !== null);
        updateData(defaultData);
        setEdit(true);
        setOpen(false);
    };

    const settearTotal = () => {
        const newDescuento = data.descuentos === '' ? 0 : parseFloat(data.descuentos);
        const total = parseFloat(data.monto) * parseFloat(data.cantidad) - newDescuento;
        updateData({ total: total });
    };

    const settearMonto = () => {
        const newDescuento = data.descuentos === '' ? 0 : parseFloat(data.descuentos);
        const subtotal: number = parseFloat(data.total) + newDescuento;
        const monto: number = subtotal / parseInt(data.cantidad);

        updateData({ monto: monto });
    };
    
    useEffect(() => {
        if (editData !== null && edit) {
            updateData(editData);
            setEdit(false);
        }
    }, [data, edit, editData])

    return (
        <Modal open={open} onClose={() => setOpen(false)} center closeOnOverlayClick={false}>
            <div className='modal-width-30'>
                <h3>Nuevo ítem</h3>
                <div className='row'>
                    <div className='col-12'>
                        <label>Código de producto</label>
                        <div className='input-group'>
                            <ControlledText
                                onChange={(e: any) => updateData({ codProducto: e.target.value })}
                                type='text'
                                required={true}
                                autoComplete={false}
                                autoFocus={false}
                                disabled={false}
                                inputMode={'text'}
                                maxLength={100}
                                pattern={'[a-zA-Z0-9s]+'}
                                placeholder={''}
                                tabIndex={0}
                                value={data.codProducto}
                            />
                            <div className='invalid-feedback'>Cargar código de producto</div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <label>Descripción</label>
                        <div className='input-group'>
                            <ControlledText
                                onChange={(e: any) => updateData({ descripcion: e.target.value })}
                                type='text'
                                required={true}
                                autoComplete={false}
                                autoFocus={false}
                                disabled={false}
                                inputMode={'text'}
                                maxLength={100}
                                pattern={'[a-zA-Z0-9s]+'}
                                placeholder={''}
                                tabIndex={0}
                                value={data.descripcion}
                            />
                            <div className='invalid-feedback'>Cargar descripción</div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8 col-xs-12'>
                        <label>Unidad de medida</label>
                        <div className='input-group'>
                            <ControlledText
                                onChange={(e: any) => updateData({ unidadMedida: e.target.value })}
                                type='text'
                                required={true}
                                autoComplete={false}
                                autoFocus={false}
                                disabled={false}
                                inputMode={'text'}
                                maxLength={100}
                                pattern={'[a-zA-Z0-9s]+'}
                                placeholder={''}
                                tabIndex={0}
                                value={data.unidadMedida}
                            />
                            <div className='invalid-feedback'>Cargar unidad de medida</div>
                        </div>
                    </div>
                    <div className='col-md-4 col-xs-12'>
                        <label>Cantidad</label>
                        <div className='input-group'>
                            <ControlledText
                                onChange={(e: any) => updateData({ cantidad: e.target.value })}
                                type='number'
                                required={true}
                                autoComplete={false}
                                autoFocus={false}
                                disabled={false}
                                inputMode={'numeric'}
                                maxLength={100}
                                pattern={'*'}
                                placeholder={''}
                                tabIndex={0}
                                value={data.cantidad.toString()}
                            />
                            <div className='invalid-feedback'>Cargar cantidad</div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                        <label>Monto</label>
                        <div className='input-group'>
                            <ControlledText
                                onChange={(e: any) => updateData({ monto: e.target.value })}
                                onBlur={settearTotal}
                                type='number'
                                required={true}
                                autoComplete={false}
                                autoFocus={false}
                                disabled={false}
                                inputMode={'numeric'}
                                maxLength={100}
                                pattern={'*'}
                                placeholder={''}
                                tabIndex={0}
                                value={data.monto.toString()}
                            />
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <label>Descuentos</label>
                        <div className='input-group'>
                            <ControlledText
                                onChange={(e: any) => updateData({ descuentos: e.target.value })}
                                onBlur={settearTotal}
                                type='number'
                                required={true}
                                autoComplete={false}
                                autoFocus={false}
                                disabled={false}
                                inputMode={'numeric'}
                                maxLength={100}
                                pattern={'*'}
                                placeholder={''}
                                tabIndex={0}
                                value={data.descuentos.toString()}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <label>Total</label>
                        <div className='input-group'>
                            <ControlledText
                                onChange={(e: any) => updateData({ total: e.target.value })}
                                onBlur={settearMonto}
                                type='number'
                                required={true}
                                autoComplete={false}
                                autoFocus={false}
                                disabled={false}
                                inputMode={'numeric'}
                                maxLength={100}
                                pattern={'*'}
                                placeholder={''}
                                tabIndex={0}
                                value={data.total.toString()}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 col-xs-12'>
                        <label>Período inicio</label>
                        <div className='input-group'>
                            <ControlledText
                                onChange={(e: any) => updateData({ periodoInicio: e.target.value })}
                                type='date'
                                required={true}
                                autoComplete={false}
                                autoFocus={false}
                                disabled={false}
                                inputMode={'text'}
                                maxLength={10}
                                pattern={'*'}
                                placeholder={''}
                                tabIndex={0}
                                value={data.periodoInicio}
                            />
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <label>Período fin</label>
                        <div className='input-group'>
                            <ControlledText
                                onChange={(e: any) => updateData({ periodoFin: e.target.value })}
                                type='date'
                                required={true}
                                autoComplete={false}
                                autoFocus={false}
                                disabled={false}
                                inputMode={'text'}
                                maxLength={10}
                                pattern={'*'}
                                placeholder={''}
                                tabIndex={0}
                                value={data.periodoFin}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Button
                            float={'right'}
                            onClick={() => SaveDetalleRecibo()}
                            text='Guardar'
                            buttonStyle='primary'
                            submit={true}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
