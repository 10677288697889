import React, { useEffect, useRef, useState } from 'react';
import { Button, Text, Textarea } from '@eltintorero/apprestosb';
import { asyncFetchApi } from '../../../utils/api';
import { useEnv } from '../../../utils/Hooks/useEnv';
import { toastSuccess } from '../../../utils/Toast';
import { Module } from '../../../utils/Components/Module';

const ModuleForm = ({ showTable, rowData }: { showTable: any; rowData: any }) => {
    const form = useRef<HTMLFormElement>(null);

    const [modulo, setModulo] = useState(rowData ? rowData.modulo : '');
    const [ruta, setRuta] = useState(rowData ? rowData.ruta : '');
    const [jsonData, setJsonData] = useState(rowData ? rowData.jsonData : '');

    const apis = useEnv();

    useEffect(() => {
        const currentForm: any = form.current;

        currentForm.addEventListener('submit', Validation);

        return () => {
            currentForm.removeEventListener('submit', Validation);
        };
    }, []);

    const Validation = (event: Event) => {
        const currentForm: any = form.current;

        event.preventDefault();
        event.stopPropagation();

        currentForm.classList.add('was-validated');
    };

    const SaveSubscriber = () => {
        const moduleData: Module = {
            cdModulo: 0,
            modulo: modulo,
            ruta: ruta,
            fechaAlta: new Date(),
            fechaBaja: null,
            jsonData: jsonData,
            activo: true,
        };

        // Edita
        if (rowData) {
            moduleData.cdModulo = rowData.cdAbonado;
            moduleData.fechaAlta = rowData.fechaAlta;
        }

        if (modulo !== '' && ruta !== '') {
            asyncFetchApi(apis.API_SET_MODULE, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                data: moduleData,
            }).then((result) => {
                if (result.error) {
                    console.log(result.error);
                } else {
                    toastSuccess('Datos cargados correctamente', '');
                    showTable();
                }
            });
        }
    };

    return (
        <form ref={form} className='needs-validation clearfix' noValidate>
            <div className='form-row'>
                <div className='col-md-6 col-xs-12'>
                    <label>Módulo</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setModulo(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={modulo}
                        />
                        <div className='invalid-feedback'>Cargar módulo</div>
                    </div>
                </div>
                <div className='col-md-6 col-xs-12'>
                    <label htmlFor='validationCustom27'>Ruta</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setRuta(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={ruta}
                        />
                        <div className='invalid-feedback'>Cargar ruta</div>
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-12'>
                    <label htmlFor='validationCustom12'>JSON data</label>
                    <div className='input-group'>
                        <Textarea
                            placeholder='JSON data'
                            row={5}
                            defaultValue={jsonData}
                            onChange={(e: any) => setJsonData(e.target.value)}
                            required={false}
                        />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-6'>
                    <Button float='left' onClick={() => showTable()} text='Cancelar' buttonStyle='light' />
                </div>
                <div className='col-6'>
                    <Button
                        float='right'
                        onClick={() => SaveSubscriber()}
                        text='Guardar'
                        buttonStyle='primary'
                        submit={true}
                    />
                </div>
            </div>
        </form>
    );
};

export default ModuleForm;
