import { Button } from '@eltintorero/apprestosb';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../generic/spinner';
import ModuleForm from './ModuleForm';
import ModuleList from './ModuleLust';

const ModuleContent = () => {
    const [showSpinner, setShowSpinner] = useState(true);
    const [table, setTable] = useState(true);
    const [rowData, setRowData] = useState<any>(null);

    useEffect(() => {}, [rowData, showSpinner]);

    const NewModel = () => {
        setTable(false);
        setRowData(false);
    };

    const ShowTable = () => {
        setTable(true);
        setRowData(null);
    };

    return (
        <div className='ms-panel'>
            <div className='ms-panel-header'>
                <h6>Módulos</h6>
            </div>
            {showSpinner ? <Spinner /> : null}
            <div className='ms-panel-body'>
                {table && !rowData ? (
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Button float='right' onClick={() => NewModel()} text='Nuevo' buttonStyle='primary' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <ModuleList setRowData={setRowData} setShowSpinner={setShowSpinner} />
                            </div>
                        </div>
                    </>
                ) : (
                    <ModuleForm showTable={ShowTable} rowData={rowData} />
                )}
            </div>
        </div>
    );
};

export default ModuleContent;
