import { Button } from '@eltintorero/apprestosb';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../generic/spinner';
import ApisForm from './ApisForm';
import ApisList from './ApisList';

const FormsContent = () => {
    const [showSpinner, setShowSpinner] = useState(true);
    const [table, setTable] = useState(true);
    const [rowData, setRowData] = useState<any>(null);

    useEffect(() => {}, [rowData, showSpinner]);

    const NewApi = () => {
        setTable(false);
        setRowData(false);
    };

    const ShowTable = () => {
        setTable(true);
        setRowData(null);
    };

    return (
        <div className='ms-panel'>
            <div className='ms-panel-header'>
                <h6>Apis</h6>
            </div>
            {showSpinner ? <Spinner /> : null}
            <div className='ms-panel-body'>
                {table && !rowData ? (
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Button float='right' onClick={() => NewApi()} text='Nuevo' buttonStyle='primary' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <ApisList setRowData={setRowData} setShowSpinner={setShowSpinner} />
                            </div>
                        </div>
                    </>
                ) : (
                    <ApisForm showTable={ShowTable} rowData={rowData} />
                )}
            </div>
        </div>
    );
};

export default FormsContent;
