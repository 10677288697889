import React, { useContext } from 'react';
import Sidenavigation from './Sidenavigation';
import Topnavigation from './Topnavigation';
import { ThemeContext } from '../contexts/theme';

declare module 'react' {
    namespace JSX {
        interface IntrinsicElements {
            div: any;
            main: any;
        }
    }
}

const Layout = ({ children }: any) => {
    const theme: any = useContext(ThemeContext);
    //const quickbar = 'ms-has-quickbar';
    const quickbar = '';

    return (
        <div
            className={`ms-body ms-aside-left-open ms-primary-theme ${quickbar} ${
                theme.state.darkMode ? 'ms-dark-theme' : ''
            }`}
        >
            <Sidenavigation />
            <main className='body-content'>
                <Topnavigation />
                <div className='ms-content-wrapper'>
                    <div className='row'>
                        <div className='col-md-12'>{children}</div>
                    </div>
                </div>
            </main>
            {/* <Quickbar /> */}
        </div>
    );
};

export default Layout;
