import { asyncFetchApi } from '../../../../utils/api';
import { storage } from '../../../../utils/functions';

export const GetMediosDePagoType = (apis: any, setShowSpinner: any, setMediosDePago: any) => {
    const subscriber = storage.get('subscriber');
    asyncFetchApi(
        apis.API_GET_MEDIOS_DE_PAGO +
            '?IdMediosDePago=0&IdAbonado=' +
            subscriber.value +
            '&CdTipoMedioPago=0&Default=0',
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: {},
        },
    ).then((result) => {
        if (result.error) {
            console.log(result.error);
        } else {
            const newMediosDePagoType = result.data.map((data: any) => {
                return {
                    value: data.idMedioPago,
                    label: data.nombre,
                };
            });

            setMediosDePago(newMediosDePagoType);
        }

        setShowSpinner(false);
    });
};

export const GetCuentasType = (apis: any, setShowSpinner: any, setCuentas: any) => {
    const subscriber = storage.get('subscriber');
    asyncFetchApi(apis.API_GET_CUENTAS + '?IdCuenta=0&IdAbonado=' + subscriber.value + '&IdPerfil=0&IdMoneda=0', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: {},
    }).then((result) => {
        if (result.error) {
            console.log(result.error);
        } else {
            const newCuentasType = result.data.map((data: any) => {
                return {
                    value: data.idCuenta,
                    label: data.nombre,
                };
            });

            setCuentas(newCuentasType);
        }

        setShowSpinner(false);
    });
};
