import { configureStore } from '@reduxjs/toolkit';
import compraReducer from './slice/CompraSlice';

const store = configureStore({
    reducer: {
        compra: compraReducer,
    },
});

export default store;
