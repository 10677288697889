import React, { Component } from 'react';
import ApisContent from '../sections/Apis/ApisContent';

class Apis extends Component {
    render() {
        return <ApisContent />;
    }
}

export default Apis;
