import React, { Component } from 'react';
import OperationContent from '../sections/Compra/CompraContent';

class Compra extends Component {
    render() {
        return <OperationContent />;
    }
}

export default Compra;
