import React, { Component } from 'react';
import SubscriberContent from '../sections/Subscriber/SubscriberContent';

class Subscriber extends Component {
    render() {
        return <SubscriberContent />;
    }
}

export default Subscriber;
