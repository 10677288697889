import React, { useEffect, useState } from 'react';
import { asyncFetchApi } from '../../../utils/api';
import { useEnv } from '../../../utils/Hooks/useEnv';
import Datatable from '../../generic/datatable';

const UserList = ({ setRowData, setShowSpinner }: { setRowData: any; setShowSpinner: any }) => {
    const [table, setTable] = useState<any>(null);
    const [titles, setTitles] = useState<any>([]);
    const apis = useEnv();

    useEffect(() => {
        if (!table) {
            // asyncFetchApi(apis.API_GET_SUBSCRIBER + '?CdSubscriber=0&activos=true', {
            //     method: 'post',
            //     headers: { 'Content-Type': 'application/json' },
            //     data: {},
            // }).then((result) => {
            //     if (result.error) {
            //         console.log(result.error);
            //     } else {
            //         setTable(result.data);
            //         setTitles([
            //             {
            //                 data: 'razonSocial',
            //                 title: 'Razón social',
            //             },
            //             {
            //                 data: 'nombreFantasia',
            //                 title: 'Nombre de fantasía',
            //             },
            //             {
            //                 data: 'contacto',
            //                 title: 'Contacto',
            //             },
            //         ]);
            //     }
            //     setShowSpinner(false);
            // });
        }

        setShowSpinner(false);
    }, [table, titles, apis, setShowSpinner]);

    const ShowForm = (rowData: any) => {
        setRowData(rowData);
    };

    return (
        <div className='mt-10'>
            {/* {table && titles.length > 0 ? (
                <Datatable
                    tableData={table}
                    titles={titles}
                    editable={true}
                    onClick={(rowData) => ShowForm(rowData)}
                    id={'cdAbonado'}
                />
            ) : null} */}
        </div>
    );
};

export default UserList;
