import React, { useEffect, useState } from 'react';
import { useEnv } from '../../../../utils/Hooks/useEnv';
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import ReactDOM from 'react-dom';
import DataTableDetalleRecibos from './DataTableDetalleRecibos';

const DetalleRecibosList = ({
    editRecibo,
    setShowSpinner,
    detalleReciboList,
    deleteDetalle,
}: {
    editRecibo: any;
    setShowSpinner: any;
    detalleReciboList: any[];
    deleteDetalle: any;
}) => {
    const [table, setTable] = useState<any>(null);
    const apis = useEnv();

    useEffect(() => {
        setShowSpinner(false);
    }, [setShowSpinner, JSON.stringify(detalleReciboList)]);

    const ShowForm = (rowData: any) => {
        editRecibo(rowData);
    };

    const DeleteItem = (rowData: any, detalleReciboListDelete: any[]) => {
        deleteDetalle(rowData, detalleReciboListDelete);
    };

    const CheckRecibido = (rowData: any) => {
        alert('Recibido');
    };

    return (
        <div className='mt-10'>
            <DataTableDetalleRecibos
                table={detalleReciboList}
                ShowForm={ShowForm}
                DeleteItem={DeleteItem}
                CheckRecibido={CheckRecibido}
            />
        </div>
    );
};

export default DetalleRecibosList;
