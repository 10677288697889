import { Button } from '@eltintorero/apprestosb';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../generic/spinner';
import UserForm from './UserForm';
import UserList from './UserList';

const UserContent = () => {
    const [showSpinner, setShowSpinner] = useState(true);
    const [table, setTable] = useState(true);
    const [rowData, setRowData] = useState<any>(null);

    useEffect(() => {}, [rowData, showSpinner]);

    const NewSubscriber = () => {
        setTable(false);
        setRowData(false);
    };

    const ShowTable = () => {
        setTable(true);
        setRowData(null);
    };

    return (
        <div className='ms-panel'>
            <div className='ms-panel-header'>
                <h6>Usuario</h6>
            </div>
            {showSpinner ? <Spinner /> : null}
            <div className='ms-panel-body'>
                {table && !rowData ? (
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Button
                                    float='right'
                                    onClick={() => NewSubscriber()}
                                    text='Nuevo'
                                    buttonStyle='primary'
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <UserList setRowData={setRowData} setShowSpinner={setShowSpinner} />
                            </div>
                        </div>
                    </>
                ) : (
                    <UserForm showTable={ShowTable} rowData={rowData} />
                )}
            </div>
        </div>
    );
};

export default UserContent;
