import React, { useEffect, useState } from 'react';
import RecibosList from './RecibosList';
import RecibosForm from './RecibosForm';
import { Spinner } from '../../generic/spinner';
import { Button } from '@eltintorero/apprestosb';

const RecibosContent = ({ values, functions }: { values: any; functions: any }) => {
    const [showSpinner, setShowSpinner] = useState(true);
    const [table, setTable] = useState(true);
    const [rowData, setRowData] = useState<any>(null);

    useEffect(() => {}, [rowData, showSpinner]);

    const NewRecibo = () => {
        setTable(false);
        setRowData(false);
    };

    const ShowTable = () => {
        setTable(true);
        setRowData(null);
    };

    return (
        <>
            {showSpinner ? <Spinner /> : null}
            <div className='ms-panel-body'>
                {table && !rowData ? (
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Button
                                    float='right'
                                    onClick={() => NewRecibo()}
                                    text='Nuevo recibo'
                                    buttonStyle='primary'
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <RecibosList setRowData={setRowData} setShowSpinner={setShowSpinner} />
                            </div>
                        </div>
                    </>
                ) : (
                    <RecibosForm
                        showTable={ShowTable}
                        rowData={rowData}
                        setShowSpinner={setShowSpinner}
                        values={values}
                        functions={functions}
                    />
                )}
            </div>
        </>
    );
};

export default RecibosContent;
