import React, { Component } from 'react';
import UserContent from '../sections/User/UserContent';

class User extends Component {
    render() {
        return <UserContent />;
    }
}

export default User;
