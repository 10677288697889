import React, { Component } from 'react';
import ModuleContent from '../sections/Modules/ModuleContent';

class Modules extends Component {
    render() {
        return <ModuleContent />;
    }
}

export default Modules;
