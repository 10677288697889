import React from 'react';
import { Select } from '@eltintorero/apprestosb';

const FormsContent = () => {
    return (
        <div className='ms-panel'>
            <div className='ms-panel-header'>
                <h6>Formulario de ejemplo</h6>
            </div>
            <div className='ms-panel-body'>
                <form>
                    <div className='form-row'>
                        <div className='col-6'></div>
                        <div className='col-6'>
                            <Select
                                defaultValue={''}
                                options={[
                                    { value: '1', label: 'Chocolate' },
                                    { value: '2', label: 'Strawberry' },
                                    { value: '3', label: 'Vanilla' },
                                ]}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FormsContent;
