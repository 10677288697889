import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar';
import { Select } from '@eltintorero/apprestosb';
import 'react-perfect-scrollbar/dist/css/styles.css';

import logo from '../../assets/img/costic/costic-logo-216x62.png';
import { storage } from '../../utils/functions';
import { Spinner } from '../generic/spinner';
import { GetSubscribers } from '../functions/Shared/Subscriber/SubscriberFunctions';
import { useEnv } from '../../utils/Hooks/useEnv';

declare module 'react' {
    namespace JSX {
        interface IntrinsicElements {
            img: any;
            ul: any;
            li: any;
        }
    }
}

const Sidenavigation = () => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [empresas, setEmpresas] = useState([]);
    const [error, setError] = useState(false);

    const apis = useEnv();

    const removeoverlay = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $('.ms-aside-overlay.ms-overlay-left').toggleClass('d-block');
    };

    const setActiveMenuItem = () => {
        $('.ms-main-aside .menu-item>a').on('click', function () {
            $(this).removeAttr('href');
            const element = $(this).parent('li');
            if (element.hasClass('active')) {
                element.removeClass('active');
                element.find('li').removeClass('active');
                element.find('.collapse').slideUp();
            } else {
                element.addClass('active');
                element.children('.collapse').slideDown();
                element.siblings('li').children('.collapse').slideUp();
                element.siblings('li').removeClass('active');
                element.siblings('li').find('li').removeClass('active');
                element.siblings('li').find('.collapse').slideUp();
            }
        });
    };

    const handleChangeEmpresa = (value: any) => {
        console.log(empresas);
        const empresa = empresas.find((empresa: any) => empresa.value === value);
        storage.set('subscriber', empresa);
    };

    const setFunctions = (result: any) => {
        const newEmpresas = result.data.map((empresa: any) => ({
            value: empresa.cdAbonado,
            label: empresa.nombreFantasia,
        }));
        setEmpresas(newEmpresas);
        if (!storage.get('subscriber')) storage.set('subscriber', newEmpresas[0]);
    };

    useEffect(() => {
        if (empresas.length === 0 && !error) {
            setShowSpinner(true);
            GetSubscribers(apis, setFunctions, setShowSpinner, setError);
        }
    }, [showSpinner]);

    useEffect(() => {
        setActiveMenuItem();
    }, []);

    return (
        <div>
            <div className='ms-aside-overlay ms-overlay-left ms-toggler' onClick={() => removeoverlay()}></div>
            <div className='ms-aside-overlay ms-overlay-right ms-toggler'></div>
            <Scrollbar id='ms-side-nav' className='side-nav fixed ms-aside-scrollable ms-aside-left'>
                {/* Logo */}
                <div className='logo-sn ms-d-block-lg'>
                    <Link className='pl-0 ml-0 text-center' to='/'>
                        <img src={logo} alt='logo' />
                    </Link>
                </div>
                {/* Navigation */}
                <ul className='accordion ms-main-aside fs-14' id='side-nav-accordion'>
                    <li className='menu-item'>
                        <div className='subscriber-container'>
                            {showSpinner ? (
                                <Spinner />
                            ) : (
                                <Select
                                    className={''}
                                    options={empresas}
                                    onChange={(e: any) => handleChangeEmpresa(e.value)}
                                    placeholder={'Seleccione...'}
                                    defaultValue={storage.get('subscriber')}
                                />
                            )}
                        </div>
                    </li>
                    {/* Dashboard */}
                    <li className='menu-item'>
                        <Link to='/'>
                            <span>
                                <i className='material-icons fs-16'>dashboard</i>Dashboard
                            </span>
                        </Link>
                    </li>
                    {/* Advanced UI Elements */}
                    <li className='menu-item'>
                        <Link to='#' className='has-chevron'>
                            <span>
                                <i className='material-icons fs-16'>settings</i>Configuración
                            </span>
                        </Link>
                        <ul
                            id='advanced-elements'
                            className='collapse'
                            aria-labelledby='advanced-elements'
                            data-parent='#side-nav-accordion'
                        >
                            <li>
                                <Link to='/apis'>Apis</Link>
                            </li>
                            <li>
                                <Link to='/modules'>Módulos</Link>
                            </li>
                            <li>
                                <Link to='/subscriber'>Subscriber</Link>
                            </li>
                            <li>
                                <Link to='/user'>Usuarios</Link>
                            </li>
                        </ul>
                    </li>
                    <li className='menu-item'>
                        <Link to='#' className='has-chevron'>
                            <span>
                                <i className='material-icons fs-16'>paid</i>Operaciones
                            </span>
                        </Link>
                        <ul className='collapse' aria-labelledby='advanced-elements' data-parent='#side-nav-accordion'>
                            <li>
                                <Link to='/consulta'>Consulta</Link>
                            </li>
                            <li>
                                <Link to='/compra'>Compra</Link>
                            </li>
                            <li>
                                <Link to='/operation'>Empleado</Link>
                            </li>
                            <li>
                                <Link to='/operation'>Movimiento</Link>
                            </li>
                            <li>
                                <Link to='/operation'>Socio</Link>
                            </li>
                        </ul>
                    </li>
                    {/* /Advanced UI Elements */}
                    {/* Tables */}
                    {/* <li className="menu-item">
                            <Link to="#" className="has-chevron"> <span><i className="material-icons fs-16">tune</i>Tables</span>
                            </Link>
                            <ul id="tables" className="collapse" aria-labelledby="tables" data-parent="#side-nav-accordion">
                                <li> <Link to="/basic-tables" >Basic Tables</Link>
                                </li>
                                <li> <Link to="/data-tables" >Data tables</Link>
                                </li>
                            </ul >
                        </li > */}
                    {/* /Tables */}
                    {/* Pages */}
                    {/* <li className='menu-item'>
                            <Link
                                to='#'
                                className='has-chevron'
                                data-toggle='collapse'
                                data-target='#pages'
                                aria-expanded='false'
                                aria-controls='pages'
                            >
                                <span>
                                    <i className='material-icons fs-16'>insert_drive_file</i>Pages
                                </span>
                            </Link>
                            <ul
                                id='pages'
                                className='collapse'
                                aria-labelledby='pages'
                                data-parent='#side-nav-accordion'
                            >
                                <li>
                                    <Link to='/error'>Error Page</Link>
                                </li>                                
                            </ul>
                        </li> */}
                </ul>
            </Scrollbar>
        </div>
    );
};

export default Sidenavigation;
