import React, { useEffect, useRef, useState } from 'react';
import { Button, Text, Textarea } from '@eltintorero/apprestosb';
import { asyncFetchApi } from '../../../utils/api';
import { useEnv } from '../../../utils/Hooks/useEnv';
import { toastSuccess } from '../../../utils/Toast';
import { Suscriber } from '../../../utils/Components/Subscriber';

const SubscriberForm = ({ showTable, rowData }: { showTable: any; rowData: any }) => {
    const form = useRef<HTMLFormElement>(null);

    const [nombreFantasia, setNombreFantasia] = useState(rowData ? rowData.nombreFantasia : '');
    const [razonSocial, setRazonSocial] = useState(rowData ? rowData.razonSocial : '');
    const [cuit, setCuit] = useState(rowData ? rowData.cuit : '');
    const [direccion, setDireccion] = useState(rowData ? rowData.direccion : '');
    const [contacto, setContacto] = useState(rowData ? rowData.contacto : '');
    const [paginaWeb, setPaginaWeb] = useState(rowData ? rowData.paginaWeb : '');
    const [logoRecibo, setLogoRecibo] = useState(rowData ? rowData.logoRecibo : '');
    const [inicioActividades, setInicioActividades] = useState(rowData ? rowData.inicioActividades : '');
    const [estadoIva, setEstadoIva] = useState(rowData ? rowData.estadoIva : '');
    const [ingresosBrutos, setIngresosBrutos] = useState(rowData ? rowData.ingresosBrutos : '');
    const [jsonData, setJsonData] = useState(rowData ? rowData.jsonData : '');

    const apis = useEnv();

    useEffect(() => {
        const currentForm: any = form.current;

        currentForm.addEventListener('submit', Validation);

        return () => {
            currentForm.removeEventListener('submit', Validation);
        };
    }, []);

    const Validation = (event: Event) => {
        const currentForm: any = form.current;

        event.preventDefault();
        event.stopPropagation();

        currentForm.classList.add('was-validated');
    };

    const SaveSubscriber = () => {
        const subscriberData: Suscriber = {
            cdAbonado: 0,
            nombreFantasia: nombreFantasia,
            razonSocial: razonSocial,
            direccion: direccion,
            contacto: contacto,
            fechaAlta: new Date(),
            fechaBaja: null,
            jsonData: jsonData,
            logoRecibo: logoRecibo,
            paginaWeb: paginaWeb,
            cuit: cuit,
            inicioActividades: inicioActividades === '' ? null : inicioActividades,
            estadoIva: estadoIva,
            ingresosBrutos: ingresosBrutos,
            activo: true,
        };

        // Edita
        if (rowData) {
            subscriberData.cdAbonado = rowData.cdAbonado;
            subscriberData.fechaAlta = rowData.fechaAlta;
        }

        if (razonSocial !== '' && direccion !== '' && contacto !== '') {
            asyncFetchApi(apis.API_SET_SUBSCRIBER, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                data: subscriberData,
            }).then((result) => {
                if (result.error) {
                    console.log(result.error);
                } else {
                    toastSuccess('Datos cargados correctamente', '');
                    showTable();
                }
            });
        }
    };

    return (
        <form ref={form} className='needs-validation clearfix' noValidate>
            <div className='form-row'>
                <div className='col-md-5 col-xs-12'>
                    <label>Nombre de fantasía</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setNombreFantasia(e.target.value)}
                            type='text'
                            required={false}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={nombreFantasia}
                        />
                    </div>
                </div>
                <div className='col-md-5 col-xs-12'>
                    <label htmlFor='validationCustom27'>Razón Social</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setRazonSocial(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={razonSocial}
                        />
                        <div className='invalid-feedback'>Cargar razón social</div>
                    </div>
                </div>
                <div className='col-md-2 col-xs-12'>
                    <label>CUIT</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setCuit(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={13}
                            pattern={'[0-9-]+'}
                            placeholder={''}
                            tabIndex={0}
                            value={cuit}
                        />
                        <div className='invalid-feedback'>Cargar CUIT</div>
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-4 col-xs-12'>
                    <label>Dirección</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setDireccion(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={direccion}
                        />
                        <div className='invalid-feedback'>Cargar la dirección</div>
                    </div>
                </div>
                <div className='col-md-4 col-xs-12'>
                    <label>Contacto</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setContacto(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={contacto}
                        />
                        <div className='invalid-feedback'>Cargar contacto</div>
                    </div>
                </div>
                <div className='col-md-4 col-xs-12'>
                    <label>Página web</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setPaginaWeb(e.target.value)}
                            type='text'
                            required={false}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'[a-zA-Z0-9s]+'}
                            placeholder={''}
                            tabIndex={0}
                            value={paginaWeb}
                        />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-3 col-xs-12'>
                    <label>Logo recibo</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setLogoRecibo(e.target.value)}
                            type='text'
                            required={false}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'[a-zA-Z0-9s ]+'}
                            placeholder={''}
                            tabIndex={0}
                            value={logoRecibo}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label>Inicio de actividades</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setInicioActividades(e.target.value)}
                            type='date'
                            required={false}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={10}
                            pattern={''}
                            placeholder={''}
                            tabIndex={0}
                            value={inicioActividades}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label>Estado IVA</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setEstadoIva(e.target.value)}
                            type='text'
                            required={false}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={50}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={estadoIva}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label>Ingresos brutos</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setIngresosBrutos(e.target.value)}
                            type='text'
                            required={false}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={50}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={ingresosBrutos}
                        />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-12'>
                    <label htmlFor='validationCustom12'>JSON data</label>
                    <div className='input-group'>
                        <Textarea
                            placeholder='JSON data'
                            row={5}
                            defaultValue={jsonData}
                            onChange={(e: any) => setJsonData(e.target.value)}
                            required={false}
                        />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-6'>
                    <Button float='left' onClick={() => showTable()} text='Cancelar' buttonStyle='light' />
                </div>
                <div className='col-6'>
                    <Button
                        float='right'
                        onClick={() => SaveSubscriber()}
                        text='Guardar'
                        buttonStyle='primary'
                        submit={true}
                    />
                </div>
            </div>
        </form>
    );
};

export default SubscriberForm;
