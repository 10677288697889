import axios, { AxiosError } from 'axios';
import { storage } from './functions';

const axiosConfig = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
};

const axiosClientInstance = axios.create(axiosConfig);
const axiosServerInstance = axios.create(axiosConfig);

axiosClientInstance.interceptors.request.use(
    (config) => {
        const headers = config.headers;
        const token = storage.get('tokenResto');

        if (!headers?.Authorization) {
            config.headers = {
                ...config.headers,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Max-Age': 86400,
                Authorization: `Bearer ${token}`,
            };
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosClientInstance.interceptors.response.use(
    (res) => res,
    (error: AxiosError) => {
        const { response: { status } = {} } = error;
        if (status === 401) {
            //document.location.href = '/login';
            storage.remove('tokenResto');
            document.location.href = '/login';
        }

        return Promise.reject(error);
    },
);

export { axiosClientInstance, axiosServerInstance };

const asyncFetchApi = async (url: string, opts?: any) => {
    return await axiosClientInstance({
        url,
        ...opts,
    })
        .then((res) => {
            return { error: null, data: res.data };
        })
        .catch((error) => {
            return { error: error, data: null };
        });
};

export { asyncFetchApi };
