import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

export const toastSuccess = (title: string, text: string) => {
    toastr.remove();
    toastr.options.positionClass = 'toast-top-right';
    toastr.success(text, title);
};

export const toastError = (title: string, text: string) => {
    toastr.remove();
    toastr.options.positionClass = 'toast-top-right';
    toastr.error(text, title);
};
