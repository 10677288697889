import React, { useEffect, useState } from 'react';
import { useEnv } from '../../../utils/Hooks/useEnv';
import Datatable from '../../generic/datatable';
import { GetSubscribers } from '../../functions/Shared/Subscriber/SubscriberFunctions';

const SubscriberList = ({ setRowData, setShowSpinner }: { setRowData: any; setShowSpinner: any }) => {
    const [table, setTable] = useState<any>(null);
    const [titles, setTitles] = useState<any>([]);
    const [error, setError] = useState(false);

    const apis = useEnv();

    const setFunctions = (result: any) => {
        setTable(result.data);

        setTitles([
            {
                data: 'razonSocial',
                title: 'Razón social',
            },
            {
                data: 'nombreFantasia',
                title: 'Nombre de fantasía',
            },
            {
                data: 'contacto',
                title: 'Contacto',
            },
        ]);
    };

    useEffect(() => {
        if (!table && !error) {
            GetSubscribers(apis, setFunctions, setShowSpinner, setError);
        }
    }, [table, titles, apis, setShowSpinner]);

    const ShowForm = (rowData: any) => {
        setRowData(rowData);
    };

    return (
        <div className='mt-10'>
            {table && titles.length > 0 ? (
                <Datatable
                    tableData={table}
                    titles={titles}
                    editable={true}
                    onClick={(rowData) => ShowForm(rowData)}
                    id={'cdAbonado'}
                />
            ) : null}
        </div>
    );
};

export default SubscriberList;
