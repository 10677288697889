import React, { useEffect, useRef, useState } from 'react';
import { Button, Radio, Select, Text, Textarea } from '@eltintorero/apprestosb';
import { useEnv } from '../../../utils/Hooks/useEnv';
import { toastSuccess } from '../../../utils/Toast';
import { GetDefaultValue, ValidateSelectStyle } from '../../../utils/Components/select';
import DetalleRecibosContent from './DetalleRecibos/DetalleRecibosContent';
import { GetRecibosType } from '../../functions/Compra/Recibos/RecibosFunctions';
import { useDispatch } from 'react-redux';
import { useSetReciboCompra, useUpdateReciboCompra } from '../../../reduxToolkit/slice/CompraSlice';
import { GetFecha, GetFormattedDateYYYYMMDD } from '../../../utils/Dates/functions';
import { DetalleRecibo } from '../../../reduxToolkit/types/CompraTypes';

const RecibosForm = ({
    showTable,
    rowData,
    setShowSpinner,
    values,
    functions,
}: {
    showTable: any;
    rowData: any;
    setShowSpinner: any;
    values: any;
    functions: any;
}) => {
    const form = useRef<HTMLFormElement>(null);

    const [reciboType, setReciboType] = useState([]);
    const [searchReciboType, setSearchReciboType] = useState(true);

    const [nroRecibo, setNroRecibo] = useState(rowData ? rowData.nroRecibo : '');
    const [observacion, setObservacion] = useState(rowData ? rowData.observacion : '');
    const [proveedor, setProveedor] = useState(rowData ? rowData.idProveedor : '');
    const [rbAdicional, setRbAdicional] = useState(rowData ? (rowData.adicional ? 'Si' : 'No') : 'No');
    const [fechaRecibo, setFechaRecibo] = useState(
        rowData ? GetFecha(rowData.fechaRecibo) : GetFormattedDateYYYYMMDD(new Date()),
    );
    const [fechaVencimientoRecibo, setFechaVencimientoRecibo] = useState(
        rowData ? GetFecha(rowData.fechaVencimientoRecibo) : '',
    );
    const [descuento, setDescuento] = useState(rowData ? rowData.descuento : 0);
    const [recibo, setRecibo] = useState(rowData ? rowData.idTipoRecibo : '');

    const [detalleReciboList, setDetalleReciboList] = useState<DetalleRecibo[]>([]);

    const dispatch = useDispatch();

    const setReciboCompra = useSetReciboCompra;
    const updateReciboCompra = useUpdateReciboCompra;
    const apis = useEnv();

    useEffect(() => {
        if (reciboType.length > 0) {
            setSearchReciboType(false);
            setShowSpinner(false);
        } else {
            setShowSpinner(true);

            if (searchReciboType) {
                setSearchReciboType(false);
                GetRecibosType(apis, setShowSpinner, setReciboType);
            }
        }
    }, [reciboType, searchReciboType]);

    useEffect(() => {
        if (rowData && rowData.detalleRecibo.length > 0) {
            setDetalleReciboList(rowData.detalleRecibo);
        }
    }, [JSON.stringify(detalleReciboList)]);

    const HandleChange = (item: any, setValue: any, setStyle: any) => {
        const value = item ? item.value : '';
        setValue(value);
        ValidateSelectStyle(value, setStyle);
    };

    const HandleRadioClick = (event: any, func: any) => {
        func(event.target.value);
    };

    const GetReciboData = (recibos: any[], value: any) => {
        const val = value !== '' ? recibos.find((recibo: any) => recibo.value === value) : '';
        return val;
    };

    const getFecha = (fechaRecibo: string) => {
        if (fechaRecibo.includes('/')) {
            const aux = fechaRecibo.split('/');

            return aux[2] + '-' + aux[1] + '-' + aux[0];
        } else {
            return fechaRecibo;
        }
    };

    const addDetalleRecibo = (data: any, edita: boolean) => {        

        if (edita) {
            const listDetalleReciboList = [...detalleReciboList];

            const newDetalleReciboList = listDetalleReciboList.map((detalleRecibo: any) => {
                if (detalleRecibo.codProducto === data.codProducto) {
                    return data;
                } else {
                    return detalleRecibo;
                }
            })

            setDetalleReciboList(newDetalleReciboList);
        } else {
            const newDetalleReciboList = [...detalleReciboList];

            newDetalleReciboList.push({
                codProducto: data.codProducto,
                descripcion: data.descripcion,
                unidadMedida: data.unidadMedida,
                cantidad: data.cantidad,
                monto: data.monto,
                descuentos: data.descuentos,
                total: data.total,
                PeriodoInicio: data.periodoInicio !== '' ? data.periodoInicio : null,
                PeriodoFin: data.periodoFin !== '' ? data.periodoFin : null,
                entregado: true,
            });           

            setDetalleReciboList(newDetalleReciboList);
        }
    };

    const deleteDetalle = (data: any, table: any[]) => {
        const newDetalleReciboList: any[] = [];
        
        const deletedDetalleReciboList = table.filter((detalle: any) => detalle.codProducto !== data.codProducto);
        
        deletedDetalleReciboList.map((element: any) => (newDetalleReciboList.push(element)));

        setDetalleReciboList(newDetalleReciboList);
    }

    const GetTotalRecibo = (detalleReciboList: DetalleRecibo[]) => {
        let total = 0;

        detalleReciboList.forEach((detalle: any) => (total += detalle.total));

        return total;
    };

    const Save = () => {
        const reciboObject = {
            cdRecibo: 0,
            nroRecibo: nroRecibo,
            idTipoRecibo: recibo,
            idProveedor: proveedor,
            fechaRecibo: GetFormattedDateYYYYMMDD(fechaRecibo),
            fechaVencimientoRecibo:
                fechaVencimientoRecibo !== '' ? GetFormattedDateYYYYMMDD(fechaVencimientoRecibo) : null,
            descuento: descuento,
            observacion: observacion,
            esAdicional: rbAdicional === 'Si',
            importe: GetTotalRecibo(detalleReciboList),
            detalleRecibo: detalleReciboList,
        };

        if (typeof rowData !== 'undefined' && rowData !== false) {
            rowData.importe = 2;
            dispatch(updateReciboCompra(reciboObject));
        } else {
            dispatch(setReciboCompra(reciboObject));
        }

        showTable(true);
    };

    return (
        <>
            <div className='form-row'>
                <div className='col-md-3 col-xs-12'>
                    <label>Nro recibo</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setNroRecibo(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'[0-9]+'}
                            placeholder={''}
                            tabIndex={0}
                            value={nroRecibo}
                        />
                        <div className='invalid-feedback'>Cargar Nro Recibo</div>
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>Tipo recibo</label>
                    <div className='input-group'>
                        {reciboType.length > 0 ? (
                            <Select
                                className={values.reciboStyle}
                                options={reciboType}
                                onChange={(e: any) => HandleChange(e, setRecibo, functions.setReciboStyle)}
                                placeholder={'Seleccione...'}
                                defaultValue={GetReciboData(reciboType, recibo)}
                            />
                        ) : null}
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>Proveedor</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setProveedor(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={proveedor}
                        />
                        <div className='invalid-feedback'>Cargar Proveedor</div>
                        {
                            // <Select
                            //     className={proveedorStyle}
                            //     options={[
                            //         { value: 'CONTACTO_1', label: 'Contacto 1' },
                            //         { value: 'CONTACTO_2', label: 'Contacto 2' },
                            //         { value: 'CONTACTO_3', label: 'Contacto 3' },
                            //         { value: 'CONTACTO_4', label: 'Contacto 4' },
                            //         { value: 'CONTACTO_5', label: 'Contacto 5' },
                            //     ]}
                            //     onChange={(e: any) => HandleChange(e, setProveedor, setProveedorStyle)}
                            //     placeholder={'Seleccione...'}
                            //     defaultValue={GetDefaultValue(proveedor)}
                            // />
                        }
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-3 col-xs-12'>
                    <label>Fecha de recibo</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setFechaRecibo(e.target.value)}
                            type='date'
                            required={false}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={10}
                            pattern={''}
                            placeholder={''}
                            tabIndex={0}
                            value={getFecha(fechaRecibo)}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label>Vencimiento de pago</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setFechaVencimientoRecibo(e.target.value)}
                            type='date'
                            required={false}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={10}
                            pattern={''}
                            placeholder={''}
                            tabIndex={0}
                            value={fechaVencimientoRecibo}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label>Descuento</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => setDescuento(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'[0-9]+'}
                            placeholder={''}
                            tabIndex={0}
                            value={descuento}
                        />
                        <div className='invalid-feedback'>Cargar descuento</div>
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-6'>
                    <label htmlFor='validationCustom12'>Observación</label>
                    <div className='input-group'>
                        <Textarea
                            placeholder='Observación'
                            row={5}
                            defaultValue={observacion}
                            onChange={(e: any) => setObservacion(e.target.value)}
                            required={false}
                        />
                    </div>
                </div>
                <div className='col-md-3'>
                    <label htmlFor='validationCustom12'>Es adicional</label>
                    <div className='input-group'>
                        <Radio
                            id={'rbEsAdicional'}
                            onChange={(e: any) => HandleRadioClick(e, setRbAdicional)}
                            opciones={[
                                {
                                    text: 'Si',
                                    value: 'Si',
                                },
                                {
                                    text: 'No',
                                    value: 'No',
                                },
                            ]}
                            horizontal={false}
                            selectedValue={rbAdicional}
                        />
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-12'>
                    <hr />
                    <h6>Detalle de recibo</h6>
                    <DetalleRecibosContent detalleReciboList={detalleReciboList} addDetalleRecibo={addDetalleRecibo} deleteDetalle={deleteDetalle} />
                </div>
            </div>
            <div className='form-row'>
                <div className='col-6'>
                    <Button float='left' onClick={() => showTable()} text='Cancelar' buttonStyle='light' />
                </div>
                <div className='col-6'>
                    <Button float='right' onClick={() => Save()} text='Guardar' buttonStyle='primary' submit={true} />
                </div>
            </div>
        </>
    );
};

export default RecibosForm;
