import React, { useEffect, useRef, useState } from 'react';
import { Button, Radio, Select, Text, Textarea } from '@eltintorero/apprestosb';
import { useEnv } from '../../../utils/Hooks/useEnv';
import { toastSuccess } from '../../../utils/Toast';
import { GetDefaultValue, ValidateSelectStyle } from '../../../utils/Components/select';
import { GetCuentasType, GetMediosDePagoType } from '../../functions/Compra/Pagos/PagosFunctions';

const TransaccionesForm = ({
    showTable,
    rowData,
    values,
    functions,
    setShowSpinner,
}: {
    showTable: any;
    rowData: any;
    values: any;
    functions: any;
    setShowSpinner: any;
}) => {
    const form = useRef<HTMLFormElement>(null);

    const [contactoStyle, setContactoStyle] = useState('');

    const [contacto, setContacto] = useState(rowData ? rowData.contacto : '');

    const [mediosDePagoType, setMediosDePagoType] = useState([]);
    const [searchMediosDePagoType, setSearchMediosDePagoType] = useState(true);
    const [cuentasType, setCuentasType] = useState([]);
    const [searchCuentasType, setSearchCuentasType] = useState(true);

    const apis = useEnv();

    useEffect(() => {
        if (mediosDePagoType.length > 0) {
            setSearchMediosDePagoType(false);
            setShowSpinner(false);
        } else {
            setShowSpinner(true);

            if (searchMediosDePagoType) {
                setSearchMediosDePagoType(false);
                GetMediosDePagoType(apis, setShowSpinner, setMediosDePagoType);
            }
        }
    }, [mediosDePagoType, searchMediosDePagoType]);

    useEffect(() => {
        if (cuentasType.length > 0) {
            setSearchCuentasType(false);
            setShowSpinner(false);
        } else {
            setShowSpinner(true);

            if (searchCuentasType) {
                setSearchCuentasType(false);
                GetCuentasType(apis, setShowSpinner, setCuentasType);
            }
        }
    }, [cuentasType, searchCuentasType]);

    // useEffect(() => {
    //     const currentForm: any = form.current;

    //     const Validation = (event: Event) => {
    //         const currentForm: any = form.current;

    //         event.preventDefault();
    //         event.stopPropagation();

    //         currentForm.classList.add('was-validated');
    //         ValidateSelectStyle(contactoStyle, setContactoStyle);
    //     };

    //     currentForm.addEventListener('submit', Validation);

    //     return () => {
    //         currentForm.removeEventListener('submit', Validation);
    //     };
    // }, [contacto, contactoStyle]);

    const HandleChange = (item: any, setValue: any, setValueStyle: any) => {
        const value = item ? item.value : '';
        setValue(value);
        ValidateSelectStyle(value, setValueStyle);
    };

    const HandleRadioClick = (event: any, func: any) => {
        func(event.target.value);
    };

    // const HandleTextChange = (value: string) => {
    //     setRutaInterna(value);
    // };

    return (
        <>
            <div className='form-row'>
                <div className='col-md-3 col-xs-12'>
                    <label>Fecha</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => functions.setFechaTransaccion(e.target.value)}
                            type='date'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'numeric'}
                            maxLength={10}
                            placeholder={''}
                            tabIndex={0}
                            value={values.fechaTransaccion}
                        />
                        <div className='invalid-feedback'>Cargar fecha</div>
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>Proveedor</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => functions.setProveedor(e.target.value)}
                            type='text'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'text'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={values.idProveedor}
                        />
                        <div className='invalid-feedback'>Cargar Proveedor</div>
                        {
                            // <Select
                            //     className={contactoStyle}
                            //     options={[
                            //         { value: 'CONTACTO_1', label: 'Contacto 1' },
                            //         { value: 'CONTACTO_2', label: 'Contacto 2' },
                            //         { value: 'CONTACTO_3', label: 'Contacto 3' },
                            //         { value: 'CONTACTO_4', label: 'Contacto 4' },
                            //         { value: 'CONTACTO_5', label: 'Contacto 5' },
                            //     ]}
                            //     onChange={(e: any) => HandleChange(e, setContacto, setContactoStyle)}
                            //     placeholder={'Seleccione...'}
                            //     defaultValue={GetDefaultValue(contacto)}
                            // />
                        }
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>Cuenta</label>
                    <div className='input-group'>
                        <Select
                            className={values.cuentaStyle}
                            options={cuentasType}
                            onChange={(e: any) => HandleChange(e, functions.setCuenta, functions.setCuentaStyle)}
                            placeholder={'Seleccione...'}
                            defaultValue={GetDefaultValue(values.cuenta)}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label htmlFor='validationCustom27'>Medio de pago</label>
                    <div className='input-group'>
                        <Select
                            className={values.medioPagoStyle}
                            options={mediosDePagoType}
                            onChange={(e: any) => HandleChange(e, functions.setMedioPago, functions.setMedioPagoStyle)}
                            placeholder={'Seleccione...'}
                            defaultValue={GetDefaultValue(values.medioPago)}
                        />
                    </div>
                </div>
                <div className='col-md-3 col-xs-12'>
                    <label>Importe</label>
                    <div className='input-group'>
                        <Text
                            onChange={(e: any) => functions.setImporteTransaccion(e.target.value)}
                            type='number'
                            required={true}
                            autoComplete={false}
                            autoFocus={false}
                            disabled={false}
                            inputMode={'numeric'}
                            maxLength={100}
                            pattern={'*'}
                            placeholder={''}
                            tabIndex={0}
                            value={values.importeTransaccion}
                        />
                        <div className='invalid-feedback'>Cargar el importe</div>
                    </div>
                </div>
            </div>
            <div className='form-row'>
                <div className='col-6'>
                    <Button float='left' onClick={() => showTable()} text='Cancelar' buttonStyle='light' />
                </div>
                <div className='col-6'>
                    <Button
                        float='right'
                        onClick={() => functions.SaveTransacciones(showTable)}
                        text='Guardar'
                        buttonStyle='primary'
                        submit={true}
                    />
                </div>
            </div>
        </>
    );
};

export default TransaccionesForm;
