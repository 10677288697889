import Swal from 'sweetalert2';

export const Modal = ({ title, text, func }: { title: string; text: string; func: any }) => {
    Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
    }).then(function (result: any) {
        if (result.value) {
            func();
        }
    });
};
