import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

const DataTableDetalleRecibos = ({
    table,
    ShowForm,
    DeleteItem,
    CheckRecibido,
}: {
    table: any[];
    ShowForm: any;
    DeleteItem: any;
    CheckRecibido: any;
}) => {
    useEffect(() => {
        const reciboDetalleTR = $('#reciboDetalle tbody tr');
        if (table.length >= 1) {
            // const last = table[table.length - 1];
            $('#reciboDetalle').DataTable().clear().draw();
            table.forEach((row: any) => $('#reciboDetalle').DataTable().row.add(row).draw(false));
        } else if (table.length === 0 && reciboDetalleTR.length === 1) {
            $('#reciboDetalle').DataTable().clear().draw();
        } else {
            $('#reciboDetalle').DataTable({
                data: table,
                columns: [
                    {
                        title: 'Código de producto',
                        data: 'codProducto',
                    },
                    {
                        title: 'Descripción',
                        data: 'descripcion',
                    },
                    {
                        title: 'Monto unitario',
                        data: 'monto',
                    },
                    {
                        title: 'Cantidad',
                        data: 'cantidad',
                    },
                    {
                        title: 'Total',
                        data: 'total',
                    },
                    {
                        title: 'Recibido',
                        data: 'entregado',
                        render: function (data, type, row) {
                            if (data) {
                                return '<input type="checkbox" checked>';
                            } else {
                                return '<input type="checkbox">';
                            }
                        },
                    },
                    {
                        data: 'codProducto',
                    },
                ],
                columnDefs: [
                    {
                        targets: 5,
                        className: 'text-center',
                    },
                    {
                        targets: 6,
                        createdCell: (td: any, cellData: any, rowData: any, row: any, col: any) =>
                            ReactDOM.render(
                                <div className='table-actions'>
                                    <a className='clear-button' onClick={() => ShowForm(rowData)} title='Editar'>
                                        <i className='fas fa-pencil-alt text-secondary' />
                                    </a>
                                    &nbsp;&nbsp;
                                    <a className='clear-button' onClick={() => DeleteItem(rowData, $('#reciboDetalle').DataTable().rows().data())} title='Borrar'>
                                        <i className='far fa-trash-alt ms-text-danger'></i>
                                    </a>
                                </div>,
                                td,
                            ),
                    },
                ],
                retrieve: true,
                searching: false,
                paging: false,
                info: false,
                language: {
                    url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
                },
            });
        }
    }, [table]);

    return (
        <div className='table-responsive'>
            <table id='reciboDetalle' className='table table-striped w-100 thead-primary text-center' />
        </div>
    );
};

export default DataTableDetalleRecibos;
