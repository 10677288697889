import { useEffect, useState } from 'react';

export enum EnvVariable {
    API_BASE_URL,
    API_GET_APIS,
    API_LOGIN,
    API_SET_APIS,
    API_GET_SUBSCRIBER,
    API_SET_SUBSCRIBER,
    API_GET_MODULE,
    API_SET_MODULE,
    API_SET_USER,
    API_GET_TIPO_RECIBO,
    API_GET_MEDIOS_DE_PAGO,
    API_GET_CUENTAS,
    API_SET_OPERACIONES_COMPLETAS,
    API_GET_OPERACIONES,
}

type EnvVariables = { [key in keyof typeof EnvVariable]: string };

const DefaultVariables: EnvVariables = {
    API_BASE_URL: 'http://localhost:3000/',
    API_GET_APIS: '',
    API_LOGIN: '',
    API_SET_APIS: '',
    API_GET_SUBSCRIBER: '',
    API_SET_SUBSCRIBER: '',
    API_GET_MODULE: '',
    API_SET_MODULE: '',
    API_SET_USER: '',
    API_GET_TIPO_RECIBO: '',
    API_GET_MEDIOS_DE_PAGO: '',
    API_GET_CUENTAS: '',
    API_SET_OPERACIONES_COMPLETAS: '',
    API_GET_OPERACIONES: '',
};

export function useEnvKey(key: EnvVariable, defaultValue: string) {
    const enviormentVaribles = useEnv();
    var variable = enviormentVaribles[key];
    if (variable === '') variable = defaultValue;
    return variable;
}

export function useEnv() {
    const [variables, setVariables] = useState(DefaultVariables);

    const getVariables = () => {
        var variables: EnvVariables = DefaultVariables;
        for (var enviormentVariable in EnvVariable) {
            var value = process.env['REACT_APP_' + enviormentVariable];
            if (value === undefined) value = '';

            variables[enviormentVariable] = value;
        }
        setVariables(variables);
    };

    useEffect(() => {
        getVariables();
    }, []);

    return variables;
}
