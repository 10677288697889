import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../generic/spinner';
import { Button } from '@eltintorero/apprestosb';
import DetalleRecibosList from '../DetalleRecibos/DetalleRecibosList';
import { ModalDetalleRecibo } from './ModalDetalleRecibo';

const DetalleRecibosContent = ({
    detalleReciboList,
    addDetalleRecibo,
    deleteDetalle,
}: {
    detalleReciboList: any[];
    addDetalleRecibo: any;
    deleteDetalle: any;
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [editData, setEditData] = useState(null);    

    useEffect(() => {
    }, [open, showSpinner, editData]);

    const EditRecibo = (rowData: any) => {
        setEditData(rowData);
        setOpen(true);
    };

    const NewRecibo = () => {
        setEditData(null);
        setOpen(true);
    };

    return (
        <>
            {showSpinner ? <Spinner /> : null}
            <div>
                {ModalDetalleRecibo(open, setOpen, addDetalleRecibo, editData)}
                <div className='row'>
                    <div className='col-md-12'>
                        <Button float='right' onClick={() => NewRecibo()} text='Nuevo detalle' buttonStyle='primary' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <DetalleRecibosList
                            editRecibo={EditRecibo}
                            setShowSpinner={setShowSpinner}
                            detalleReciboList={detalleReciboList}
                            deleteDetalle={deleteDetalle}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetalleRecibosContent;
