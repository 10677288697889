import React, { Component } from 'react';
import ConsultaContent from '../sections/Consulta/ConsultaContent';

class Consulta extends Component {
    render() {
        return <ConsultaContent />;
    }
}

export default Consulta;
