import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/theme';

declare module 'react' {
    namespace JSX {
        interface IntrinsicElements {
            label: any;
            input: any;
            span: any;
        }
    }
}

const Darkmode = () => {
    const theme: any = useContext(ThemeContext);
    const darkMode: boolean = theme.state.darkMode;

    const adddarkmode = (e: any) => {
        if (darkMode) {
            theme.dispatch({ type: 'LIGHTMODE' });
        } else {
            theme.dispatch({ type: 'DARKMODE' });
        }
    };

    return (
        <label className='ms-switch'>
            <input type='checkbox' id='dark-mode' onChange={(e: any) => adddarkmode(e)} checked={darkMode} />
            <span className='ms-switch-slider round' />
        </label>
    );
};

export default Darkmode;
