import React, { Component } from 'react';
import Homecontent from '../sections/Dashboard/Homecontent';

class Dashboard extends Component {
    render() {
        return <Homecontent />;
    }
}

export default Dashboard;
